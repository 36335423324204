import React from 'react';
import { Link } from 'react-router-dom';
import arvrmrImage from '../assets/images/my new/arvrmr.jpg';
import trainingImage from '../assets/images/my new/ComfyUI_00496_.png';
import marketingImage from '../assets/images/my new/Reydar_Honeywell_Interactive_Brakes-scaled.jpg';
import tryonImage from '../assets/images/my new/frame-99.jpg';
import simulationImage from '../assets/images/my new/ComfyUI_00500_.png';
import Footer from './Footer';

const ArVr = () => {
  return (
    <>
      <div className="page-heading normal-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <br></br>
              <h6>IDA Creative Studios</h6>
              <h2>AR/VR/MR/XR</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="item-details-page">
        <div className="container">
          <div className="row">
            <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-12">
              <div className="section-heading">
                <br></br><br></br>
                <h2>Step into new <em>realities</em></h2>
                <div className="line-dec mt-2"></div>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-7">
              <div className="left-image">
                <img src={arvrmrImage} alt="AR/VR/MR/XR"
                 style={{ 
                  borderRadius: '20px', 
                  height: 'auto', 
                  width: '100%', 
                  maxWidth: '700px', 
                  objectFit: 'cover' 
                }}  
                />
              </div>
            </div>
            <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-5 align-self-center">
              <h3>Enter the Next Reality</h3>
              <p className="mt-3">
              Augmented reality (AR), virtual reality (VR), mixed reality (MR), and extended reality (XR) collectively herald a new era of experiential immersion, transcending the boundaries of conventional perception to usher humanity into uncharted realms of possibility. With augmented reality, digital overlays seamlessly integrate with our tangible surroundings, enriching our perception of reality with layers of digital information and interactive experiences. Meanwhile, virtual reality beckons us into entirely immersive realms, where the constraints of physics yield to the boundless imagination, offering a portal to worlds limited only by the depths of creativity. Mixed reality blurs the lines between the physical and digital, intertwining the fabric of both realms to forge a symbiotic landscape where virtual and tangible elements coalesce in perfect harmony.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="author-page1">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
              <div className="section-heading" style={{marginTop:'5px'}}>
                <h2 className="text-center">What We <em>offer</em></h2>
                <br />
                <div className="line-dec"></div>
                <h5>
                Immerse yourself in endless possibilities with IDA's AR, VR, MR, and XR solutions. From interactive marketing campaigns to immersive experiences, we harness the latest technologies to create captivating digital worlds that engage and inspire, driving meaningful connections and memorable experiences for users across all platforms and devices.
                </h5>
              </div>
            </div>

            <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={trainingImage} alt="Industrial VR & XR Training"  style={{ 
                  borderRadius: '20px', 
                  height: '248', 
                  width: '100%', 
                  maxWidth: '700px',
                  maxHeight: '700px', 
                  objectFit: 'cover'  
                }}   />
                    <h4 className="text-center">Industrial VR & XR Training</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>
                        Get hands-on training with VR and XR. Experience immersive learning for industry skills and safety.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={marketingImage} alt="Interactive AR Marketing"  style={{ 
                  borderRadius: '20px', 
                  height: '248', 
                  width: '100%', 
                  maxWidth: '700px',
                  maxHeight: '700px', 
                  objectFit: 'cover'  
                }}   />
                    <h4 className="text-center">Interactive AR Marketing</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>
                        Engage customers with AR. Let them interact, visualize, experience, and explore products in new ways.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-down" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={tryonImage} alt="Virtual Try-on Solutions"  style={{ 
                    borderRadius: '20px', 
                    height: '248', 
                    width: '100%', 
                    maxWidth: '700px',
                    maxHeight: '700px', 
                    objectFit: 'cover'
                   
                }}  />
                    <h4 className="text-center">Virtual Try-on Solutions</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>
                        See before you buy with virtual try-ons. Visualize products in real-time for a personalized shopping experience.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={simulationImage} alt="Custom Simulations"  style={{ 
                   borderRadius: '20px', 
                   height: '248', 
                   width: '100%', 
                   maxWidth: '700px',
                   maxHeight: '700px', 
                   objectFit: 'cover'
                }}   />
                    <h4 className="text-center">Custom Simulations</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>
                        Simulate anything you need. From training scenarios to virtual environments, we've got you covered.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container1">
              <div style={{ fontWeight: 900 }} className="btn">
                <Link to ="/contact">Join Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArVr;
