import React from 'react';
import '../vendor/bootstrap/css/bootstrap.min.css'; 
// import '../assets/css/fontawesome.css'; 
// import '../assets/css/templatemo-liberty-market.css'; 
// import '../assets/css/owl.css'; 
// import '../assets/css/animate.css';
import '../assets/css/index.css';

import comfyImage from '../assets/images/my new/ComfyUI_00249_.png';

function AboutSection() {
    return (
      <div className="item-details-page">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" className="col-lg-12">
              <div className="section-heading mt-5">
                <h2>About <em>IDA</em></h2>
                <div className="line-dec mt-2"></div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="left-image">
                <img 
                  data-aos="fade-right" 
                   data-aos-duration="1000"
                  src={comfyImage}
                  alt="" 
                  style={{ borderRadius: '20px' }} 
                />
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-5 align-self-center">
              <h3>Shaping Tomorrow's Reality</h3>
              <p className="mt-3">
                At IDA, we're pioneers in reshaping industries and businesses through cutting-edge solutions tailored for Web3.0 and Industry 4.0. Our mission? To propel you forward into a new era of immersive technology.
                
                We specialize in crafting bespoke solutions that harness the power of AR (Augmented Reality), VR (Virtual Reality), MR (Mixed Reality), and XR (Extended Reality), unlocking endless possibilities for your business.
                
                Step into the future with us as we redefine the landscape of industries through our expertise in Game Development, Motion Graphics, Immersive Media, Visual Effects, and Virtual Production.
                
                Join IDA on the journey to an immersive tomorrow!
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default AboutSection;