import React from 'react';
import { Link } from 'react-router-dom';
import './projects.css'

import tw from '../assets/images/img/tw2.jpg';

function Project5() {
  return (
    <div className="item-details-page">
      <div className="container">
        <div className="row">
          <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
            <div className="section-heading">
              <h2 style={{ marginTop: '100px' }}>
                <br></br><br></br>
                3D Walkthrough - <em>Discover Our Immersive 3D Walkthrough Experience</em>
              </h2>
              <div className="line-dec mt-2"></div>
            </div>
          </div>
          <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-7">
            <div className="left-image">
              <img
                src={tw}
                alt="Wordwide Artwork Ground"
                style={{ 
                  borderRadius: '20px', 
                  height: 'auto', 
                  width: '100%', 
                  maxWidth: '700px', 
                  objectFit: 'cover' 
                }}
              />
            </div>
          </div>
          <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-5 align-self-center">
            <h3>Experience Cutting-Edge 3D Walkthroughs</h3>
            <p className="mt-3">
              Dive into our state-of-the-art 3D walkthroughs that bring architectural and design projects to life. Navigate through virtual spaces with unprecedented detail, visualize every aspect of your project, and experience the future of design. Our immersive 3D tours offer a revolutionary way to explore and interact with your projects, enhancing your understanding and engagement from every angle. Experience the power of 3D visualization today!
            </p>
         </div>
        </div>
      </div>

      <div className="author-page">
        <div className="container">
          <div className="row">
            <div data-aos="fade-down" data-aos-duration="1000" className="col-lg-12">
              <div className="section-heading">
                <h2 className="text-center">
                  <br></br>
                  Key <em>Features</em>
                </h2>
                
                <div className="line-dec"></div>
                <ul>
                  <li>Immersive Virtual Tours: Explore architectural designs and interior spaces with stunning 3D detail.</li>
                  <li>Interactive Navigation: Move through virtual environments, interact with design elements, and get a true feel for the space.</li>
                  <li>Realistic Visualizations: Experience lifelike renderings of rooms, buildings, and landscapes before they are built.</li>
                  <li>Customizable Views: Adjust lighting, materials, and layouts in real-time to see different design possibilities.</li>
                  <li>Enhanced Project Understanding: Gain a comprehensive understanding of spatial relationships and design aesthetics.</li>
                  <li>Collaborative Design Reviews: Share and discuss 3D walkthroughs with team members or clients for more effective feedback.</li>
                  <li>Future-Ready Technology: Utilize cutting-edge 3D technology to stay ahead in the design and architectural fields.</li>
                </ul>
              </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
              <div style={{ marginBottom: '10px' }} className="section-heading">
                <h2 className="text-center">
                  Get <em>Started</em>
                </h2>
               
                <div className="line-dec"></div>
                <h6>
                 Experience the Future of Design with 3D Walkthroughs! Dive into immersive virtual tours that bring architectural projects and interiors to life. Navigate through detailed 3D models, customize designs in real-time, and visualize spaces before they’re built. Collaborate seamlessly, explore innovative layouts, and get a clear vision of your design. Transform how you see and interact with space!
               </h6>
              </div>
            </div>

            <div className="container1" style={{ marginTop: '20px' }}> {/* Added marginTop for spacing */}
              <div style={{ fontWeight: '900' }} className="btn">
                <Link to="/contact">Join Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Project5;
