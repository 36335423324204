import React from 'react';
import { Link } from 'react-router-dom';
import '../vendor/bootstrap/css/bootstrap.min.css'; 
// import '../assets/css/fontawesome.css'; 
// import '../assets/css/templatemo-liberty-market.css'; 
// import '../assets/css/owl.css'; 
import '../assets/css/animate.css';
import '../assets/css/index.css';


import gameDevImage from '../assets/images/my new/gd.jpg';
import generativesAIImage from '../assets/images/my new/ga.jpg';
import renderingImage from '../assets/images/my new/3d.jpg';
import animationImage from '../assets/images/my new/2.jpg';
import motionGraphicsImage from '../assets/images/my new/mg.avif';
import vfxImage from '../assets/images/my new/vfx.jpg';
import arvrImage from '../assets/images/my new/AR.png';
import virtualProductionImage from '../assets/images/my new/VP.png';
import cgImage from '../assets/images/my new/cg.png';
import uiuxImage from '../assets/images/my new/ui.jpg';
import academicAlliancesImage from '../assets/images/my new/AC.png';
import videoEditingImage from '../assets/images/my new/Video.png';

const Services = () => {
    return (
      <div>
  <div className="author-page" id="explore">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-heading text-center">
            <br></br>
            <h2>Our <em>Services</em></h2>
            <div className="line-dec mt-2"></div>
          </div>
        </div>

        <div data-aos="fade-right" data-aos-duration="500" className="col-lg-3 col-md-6">
          <div className="item">
            <div className="row">
              <div className="col-lg-12">
                <img src={gameDevImage} alt="Game Development" className='simg' />
                <h4 className="text-center">Game Development</h4>
              </div>
              <div className="col-lg-12">
                <div className="main-button">
                  <Link to="/gamedev">View Details</Link> {/* Changed to Link component */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div data-aos="fade-up" data-aos-duration="500" className="col-lg-3 col-md-6">
          <div className="item">
            <div className="row">
              <div className="col-lg-12">
                <img src={generativesAIImage} alt="Generatives AI" className='simg'  />
                <h4 className="text-center">Generatives AI</h4>
              </div>
              <div className="col-lg-12">
                <div className="main-button">
                  <Link to="/genai">View Details</Link> {/* Changed to Link component */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div data-aos="fade-down" data-aos-duration="500" className="col-lg-3 col-md-6">
          <div className="item">
            <div className="row">
              <div className="col-lg-12">
                <img src={renderingImage} alt="3D Modeling/Rendering" className='simg' />
                <h4 className="text-center">3D Modeling/Rendering</h4>
              </div>
              <div className="col-lg-12">
                <div className="main-button">
                  <Link to="/3d-modelling">View Details</Link> {/* Changed to Link component */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div data-aos="fade-left" data-aos-duration="500" className="col-lg-3 col-md-6">
          <div className="item">
            <div className="row">
              <div className="col-lg-12">
                <img src={animationImage} alt="2D/3D Animation" className='simg' />
                <h4 className="text-center">2D/3D Animation</h4>
              </div>
              <div className="col-lg-12">
                <div className="main-button">
                  <Link to="/2d-animation">View Details</Link> {/* Changed to Link component */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Repeat for other rows */}
      <div className="row">
        <div data-aos="fade-right" data-aos-duration="500" className="col-lg-3 col-md-6">
          <div className="item">
            <div className="row">
              <div className="col-lg-12">
                <img src={motionGraphicsImage} alt="Motion Graphics" className='simg' />
                <h4 className="text-center">Motion Graphics</h4>
              </div>
              <div className="col-lg-12">
                <div className="main-button">
                  <Link to="/motion-graphics">View Details</Link> {/* Changed to Link component */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div data-aos="fade-up" data-aos-duration="500" className="col-lg-3 col-md-6">
          <div className="item">
            <div className="row">
              <div className="col-lg-12">
                <img src={vfxImage} alt="VFX" className='simg' />
                <h4 className="text-center">VFX</h4>
              </div>
              <div className="col-lg-12">
                <div className="main-button">
                  <Link to="/vfx">View Details</Link> {/* Changed to Link component */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div data-aos="fade-down" data-aos-duration="500" className="col-lg-3 col-md-6">
          <div className="item">
            <div className="row">
              <div className="col-lg-12">
                <img src={arvrImage} alt="AR/VR/MR/XR" className='simg' />
                <h4 className="text-center">AR/VR/MR/XR</h4>
              </div>
              <div className="col-lg-12">
                <div className="main-button">
                  <Link to="/ar-vr">View Details</Link> {/* Changed to Link component */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div data-aos="fade-left" data-aos-duration="500" className="col-lg-3 col-md-6">
          <div className="item">
            <div className="row">
              <div className="col-lg-12">
                <img src={virtualProductionImage} alt="Virtual Production" className='simg' />
                <h4 className="text-center">Virtual Production</h4>
              </div>
              <div className="col-lg-12">
                <div className="main-button">
                  <Link to="/virtual-production">View Details</Link> {/* Changed to Link component */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Repeat for the final row */}
      <div className="row">
        <div data-aos="fade-right" data-aos-duration="500" className="col-lg-3 col-md-6">
          <div className="item">
            <div className="row">
              <div className="col-lg-12">
                <img src={cgImage} alt="CG" className='simg' />
                <h4 className="text-center">Computer Graphics</h4>
              </div>
              <div className="col-lg-12">
                <div className="main-button">
                  <Link to="/cg">View Details</Link> {/* Changed to Link component */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div data-aos="fade-up" data-aos-duration="500" className="col-lg-3 col-md-6">
          <div className="item">
            <div className="row">
              <div className="col-lg-12">
                <img src={uiuxImage} alt="UI/UX" className='simg' />
                <h4 className="text-center">UI/UX</h4>
              </div>
              <div className="col-lg-12">
                <div className="main-button">
                  <Link to="/ui-ux">View Details</Link> {/* Changed to Link component */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div data-aos="fade-down" data-aos-duration="500" className="col-lg-3 col-md-6">
          <div className="item">
            <div className="row">
              <div className="col-lg-12">
                <img src={academicAlliancesImage} alt="Academic Alliances" className='simg' />
                <h4 className="text-center">Academic Alliances</h4>
              </div>
              <div className="col-lg-12">
                <div className="main-button">
                  <Link to="/academic-alliances">View Details</Link> {/* Changed to Link component */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div data-aos="fade-left" data-aos-duration="500" className="col-lg-3 col-md-6">
          <div className="item">
            <div className="row">
              <div className="col-lg-12">
                <img src={videoEditingImage} alt="Voice Over" className='simg' />
                <h4 className="text-center">Video Editing</h4>
              </div>
              <div className="col-lg-12">
                <div className="main-button">
                  <Link to="/video-editing">View Details</Link> {/* Changed to Link component */}
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>
    );
  };
  
  export default Services;