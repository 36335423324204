import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './projects.css'


import vto2 from '../assets/images/img/vto2.jpg';

function Project5() {
    const panoramaContainer = useRef(null);

  useEffect(() => {
    if (panoramaContainer.current) {
      const config = {
        autoLoad: true,
        compass: false,
        default: {
          firstScene: "office",
          sceneFadeDuration: 1000
        },
        scenes: {
          office: {
            hfov: 180,
            pitch: 0,
            yaw: 0,
            type: "equirectangular",
            panorama: "https://res.cloudinary.com/aptus/image/upload/v1550825644/trapboveneinde_dvoxir.jpg",
            hotSpots: [
              {
                pitch: 4,
                yaw: 21.8,
                type: "scene",
                sceneId: "meetingroom"
              }
            ]
          },
          meetingroom: {
            hfov: 180,
            pitch: 0,
            yaw: 90,
            type: "equirectangular",
            panorama: "https://res.cloudinary.com/aptus/image/upload/v1550826131/vergaderzaal_sq7baz.jpg",
            hotSpots: [
              {
                pitch: 8,
                yaw: -58,
                type: "scene",
                sceneId: "office"
              }
            ]
          },
        }
      };

      window.pannellum.viewer(panoramaContainer.current, config);
    }
  }, []);
  return (
    <div className="item-details-page">
      <div className="container">
        <div className="row">
          <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
            <div className="section-heading">
              <h2 style={{ marginTop: '100px' }}>
                <br></br><br></br>
                Virtual Tour - <em>Discover 3D Virtual Journeys</em>
              </h2>
              <div className="line-dec mt-2"></div>
            </div>
          </div>
          <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-7">
            <div className="left-image">
              {/* <img
                src={vto2}
                alt="Wordwide Artwork Ground"
                style={{ borderRadius: '20px', height: '500px',width:'700px',marginTop: '10px' }}
              /> */}
               <div 
                id="panorama" 
                ref={panoramaContainer}
                style={{ height: '50Vh' }}
              ></div>
            </div>
          </div>
          <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-5 align-self-center">
          <h3>Explore, Engage, Experience</h3>
             <p className="mt-3">
               Step into a new dimension with our cutting-edge virtual tours. Experience spaces like never before, from architectural marvels to interactive exhibits. Navigate through detailed environments, gain new perspectives, and engage with content in a fully immersive way. Our virtual tours provide an innovative approach to exploration and discovery, making distant or complex projects feel accessible and tangible. Begin your virtual journey today!
             </p>
          </div>
        </div>
      </div>

      <div className="author-page">
        <div className="container">
          <div className="row">
            <div data-aos="fade-down" data-aos-duration="1000" className="col-lg-12">
              <div className="section-heading">
                <h2 className="text-center">
                  <br></br>
                  Key <em>Features</em>
                </h2>
                
                <div className="line-dec"></div>
                <ul>
                  <li>Immersive Virtual Tours: Explore diverse locations and experiences with high-definition 3D views.</li>
                  <li>Interactive Exploration: Navigate through virtual spaces and interact with key features to fully experience each environment.</li>
                  <li>Detailed Visualizations: See realistic representations of various sites and landmarks from every angle.</li>
                  <li>Customizable Experiences: Personalize your virtual tour with adjustable settings and viewpoints to match your interests.</li>
                  <li>Enhanced Understanding: Gain a deeper insight into spaces and locations with comprehensive virtual walkthroughs.</li>
                  <li>Collaborative Viewing: Share virtual tours with others to discuss and review locations collectively.</li>
                  <li>State-of-the-Art Technology: Leverage advanced virtual tour technology to explore and showcase spaces like never before.</li>
               </ul>
              </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
              <div style={{ marginBottom: '10px' }} className="section-heading">
                <h2 className="text-center">
                  Get <em>Started</em>
                </h2>
               
                <div className="line-dec"></div>
                <h6>Embark on Unforgettable Virtual Tours - Explore the World from Your Screen! Dive into stunning virtual journeys that transport you to iconic landmarks, historical sites, and exotic destinations. Enjoy interactive, 360-degree views, detailed explorations, and immersive experiences right from the comfort of your home. Discover new places, learn about their histories, and enjoy guided tours like never before. Let your adventures begin!</h6>
              </div>
            </div>

            <div className="container1" style={{ marginTop: '20px' }}> {/* Added marginTop for spacing */}
              <div style={{ fontWeight: '900' }} className="btn">
                <Link to="/contact">Join Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Project5;
