import React from 'react';
import { Link } from 'react-router-dom';
import './projects.css'

import vt from '../assets/images/img/tryon.jpg';

function Project5() {
  return (
    <div className="item-details-page">
      <div className="container">
        <div className="row">
          <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
            <div className="section-heading">
              <h2 style={{ marginTop: '100px' }}>
                <br></br><br></br>
                Virtual Try-On - <em>Augmented Reality in Wholesale Fashion</em>
              </h2>
              <div className="line-dec mt-2"></div>
            </div>
          </div>
          <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-7">
            <div className="left-image">
              <img
                src={vt}
                alt="Wordwide Artwork Ground"
                style={{ 
                  borderRadius: '20px', 
                  height: 'auto', 
                  width: '100%', 
                  maxWidth: '700px', 
                  objectFit: 'cover' 
                }}
              />
            </div>
          </div>
          <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-5 align-self-center">
            <h3>Discover, Style, Showcase</h3>
            <p className="mt-3">
            Step into the future of fashion with our Virtual Try-On platform. Explore how augmented reality is transforming the way we shop for clothes. 
            Try on outfits from the comfort of your home, connect with fashion enthusiasts, and embrace the fusion of technology and style. 
            Discover, experiment, and redefine your fashion journey!
            </p>
          </div>
        </div>
      </div>

      <div className="author-page">
        <div className="container">
          <div className="row">
            <div data-aos="fade-down" data-aos-duration="1000" className="col-lg-12">
              <div className="section-heading">
                <h2 className="text-center">
                  <br></br>
                  Key <em>Features</em>
                </h2>
                
                <div className="line-dec"></div>
                <ul>
                <li>Virtual Wardrobe: Try on the latest fashion trends virtually and see how they fit before you buy.</li>
                <li>Fashion Community: Connect with fashion enthusiasts, share style tips, and collaborate on fashion projects.</li>
                <li>Augmented Reality Experience: Experience the future of shopping with AR-enhanced try-ons and styling suggestions.</li>
                <li>Global Fashion Access: Explore and try on outfits from designers around the world, all from your home.</li>
                <li>Style Inspiration: Discover new fashion trends, get inspired by curated looks, and experiment with different styles.</li>
                <li>Personalized Feedback: Receive styling advice and feedback from fashion experts and peers to perfect your look.</li>
                <li>Virtual Fashion Shows: Participate in virtual fashion shows and showcase your style in a global arena.</li>
                </ul>
              </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
              <div style={{ marginBottom: '10px' }} className="section-heading">
                <h2 className="text-center">
                  Get <em>Started</em>
                </h2>
               
                <div className="line-dec"></div>
                <h6>
                Join the Virtual Try-On Experience - Augmented Reality in Wholesale Fashion! Step into the future of fashion and explore endless possibilities. Connect with fashion enthusiasts, try on the latest trends, and showcase your style to the world. Elevate your fashion game and let your imagination run wild!
                </h6>
              </div>
            </div>

            <div className="container1" style={{ marginTop: '20px' }}> {/* Added marginTop for spacing */}
              <div style={{ fontWeight: '900' }} className="btn">
                <Link to="/contact">Join Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Project5;
