import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from './Footer';

// Importing images
import gameDevImage from '../assets/images/my new/Game dev.png';
import genAiImage from '../assets/images/my new/GenAi.png';
import threeDModelingImage from '../assets/images/my new/rend.png';
import twoDAnimationImage from '../assets/images/my new/anim.png';
import motionGraphicsImage from '../assets/images/my new/mot.png';
import vfxImage from '../assets/images/my new/VFX.png';
import arVrImage from '../assets/images/my new/AR.png';
import virtualProductionImage from '../assets/images/my new/VP.png';
import cgImage from '../assets/images/my new/cg.png';
import uiUxImage from '../assets/images/my new/ui.png';
import academicAlliancesImage from '../assets/images/my new/AC.png';
import videoEditingImage from '../assets/images/my new/Video.png';

const Details = () => {
  React.useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="author-page">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" data-aos-duration="2000" className="col-lg-12">
              <div className="section-heading">
                <h2 style={{ marginTop: '145px' }} className="text-center">
                  Explore our <em>Services</em>
                </h2>
                <div className="line-dec mt-2"></div>
              </div>
            </div>
          </div>

          {/* First Row */}
          <div className="row">
            <div data-aos="fade-right" data-aos-duration="2000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={gameDevImage} alt="Game Development" style={{ borderRadius: '20px' }} />
                    <h4 className="text-center">Game Development</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="main-button">
                      <a href="/gamedev">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="2000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={genAiImage} alt="Generative AI" style={{ borderRadius: '20px' }} />
                    <h4 className="text-center">Generative AI</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="main-button">
                      <a href="/genai">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-down" data-aos-duration="2000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={threeDModelingImage} alt="3D Modeling/Rendering" style={{ borderRadius: '20px' }} />
                    <h4 className="text-center">3D Modeling/Rendering</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="main-button">
                      <a href="/3d-modelling">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-left" data-aos-duration="2000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={twoDAnimationImage} alt="2D/3D Animation" style={{ borderRadius: '20px' }} />
                    <h4 className="text-center">2D/3D Animation</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="main-button">
                      <a href="/2d-animation">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Second Row */}
          <div className="row">
            <div data-aos="fade-right" data-aos-duration="2000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={motionGraphicsImage} alt="Motion Graphics" style={{ borderRadius: '20px' }} />
                    <h4 className="text-center">Motion Graphics</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="main-button">
                      <a href="/motion-graphics">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="2000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={vfxImage} alt="VFX" style={{ borderRadius: '20px' }} />
                    <h4 className="text-center">VFX</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="main-button">
                      <a href="/vfx">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-down" data-aos-duration="2000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={arVrImage} alt="AR/VR/MR/XR" style={{ borderRadius: '20px' }} />
                    <h4 className="text-center">AR/VR/MR/XR</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="main-button">
                      <a href="/ar-vr">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-left" data-aos-duration="2000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={virtualProductionImage} alt="Virtual Production" style={{ borderRadius: '20px' }} />
                    <h4 className="text-center">Virtual Production</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="main-button">
                      <a href="/virtual-production">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Third Row */}
          <div className="row">
            <div data-aos="fade-right" data-aos-duration="2000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={cgImage} alt="CG" style={{ borderRadius: '20px' }} />
                    <h4 className="text-center">CG</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="main-button">
                      <a href="/cg">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="2000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={uiUxImage} alt="UI/UX" style={{ borderRadius: '20px' }} />
                    <h4 className="text-center">UI/UX</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="main-button">
                      <a href="/ui-ux">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-down" data-aos-duration="2000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={academicAlliancesImage} alt="Academic Alliances" style={{ borderRadius: '20px' }} />
                    <h4 className="text-center">Academic Alliances</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="main-button">
                      <a href="/academic-alliances">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-left" data-aos-duration="2000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={videoEditingImage} alt="Video Editing" style={{ borderRadius: '20px' }} />
                    <h4 className="text-center">Video Editing</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="main-button">
                      <a href="/video-editing">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Details;
