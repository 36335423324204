import React from 'react';
import 'aos/dist/aos.css';
import './projects.css'
import { Link } from 'react-router-dom';

import game1 from '../assets/images/img/car.jpg'

const Project1 = () => {
  return (
    <div className="item-details-page">
      <div className="container">
        <div className="row">
          <div
            data-aos="fade-up"
            data-aos-duration="2000"
            className="col-lg-12"
          >
            <div className="section-heading">
              <h2 style={{ marginTop: '100px' }}>
                <br></br><br></br>
                Turbo Thrust - <em>Mobile Racing Game.</em>
              </h2>
              <div className="line-dec mt-2"></div>
            </div>
          </div>

          <div
            data-aos="fade-right"
            data-aos-duration="2000"
            className="col-lg-7"
          >
            <div className="left-image">
              <img
                src={game1}
                alt="Turbo Thrust"
                style={{ 
                  borderRadius: '20px', 
                  height: 'auto', 
                  width: '100%', 
                  maxWidth: '700px', 
                  objectFit: 'cover' 
                }}
              />
            </div>
          </div>

          <div
            data-aos="fade-right"
            data-aos-duration="2000"
            className="col-lg-5 align-self-center"
          >
            <h3>Play, Create, Conquer</h3>
            <p className="mt-3">
              Welcome to Turbo Thrust, the ultimate mobile racing game
              experience! Get ready to rev your engines and race against players
              from around the world in adrenaline-pumping challenges.
            </p>
          </div>
        </div>
      </div>

      <div className="author-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                data-aos="fade-left"
                data-aos-duration="2000"
                className="section-heading"
              >
                <br></br>
                <h2 className="text-center">
                  Key <em>Features</em>
                </h2>
                
                <div className="line-dec"></div>
                <ul>
                  <li>
                    High-Speed Racing: Experience high-speed racing action right
                    at your fingertips.
                  </li>
                  <li>
                    Customizable Cars: Choose from a variety of customizable
                    cars and unlock new upgrades.
                  </li>
                  <li>
                    Multiplayer Madness: Compete in thrilling multiplayer races
                    or test your skills in solo challenges.
                  </li>
                  <li>
                    Diverse Tracks: Explore stunning tracks and environments,
                    from city streets to exotic landscapes.
                  </li>
                  <li>
                    Regular Updates: Stay ahead of the competition with regular
                    updates and new content additions.
                  </li>
                  <li>
                    Gameplay Showcase: Showcase exciting gameplay footage or
                    screenshots highlighting the intense racing action and
                    stunning visuals of Turbo Thrust.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div
                data-aos="fade-up"
                data-aos-duration="2000"
                style={{ marginBottom: '10px' }}
                className="section-heading"
              >
                <h2 className="text-center">
                  Get <em>Started</em>
                </h2>
                
                <div className="line-dec"></div>
                <h6>
                  Connect with fellow racers and share your experiences on
                  social media platforms. Participate in tournaments, events,
                  and leaderboards to earn rewards and climb the ranks. Join a
                  vibrant community of racing enthusiasts and stay updated on
                  the latest news and announcements.
                </h6>
              </div>
            </div>


            <div className="container1" style={{ marginTop: '20px' }}>
              <div style={{ fontWeight: '900' }} className="btn">
                
                <Link to="/contact">Join Now</Link>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project1;
