import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import 'aos/dist/aos.css'; // Ensure AOS (Animate On Scroll) is imported
import Footer from './Footer';

// Importing images
import computerImage from '../assets/images/my new/an-impressive-looking-computer.jpg';
import levelPrototyping from '../assets/images/my new/LevelProtoyping.png';
import char from '../assets/images/my new/char.png';
import asset from '../assets/images/my new/asset.png';
import inho from '../assets/images/my new/inho.png';

const VideoEditing = () => {
    return (
        <>
            <div className="page-heading normal-space">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <br></br>
                            <h6>IDA Creative Studios</h6>
                            <h2>Video Editing</h2>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="item-details-page">
                <Container>
                    <Row>
                        <Col lg={12} data-aos="fade-up" data-aos-duration="1000">
                            <div className="section-heading">
                                <br></br><br></br>
                                <h2>Editing stories, creating <em>magic.</em></h2>
                                <div className="line-dec mt-2"></div>
                            </div>
                        </Col>
                        <Col lg={7} data-aos="fade-right" data-aos-duration="1000">
                            <div className="left-image">
                                <img src={computerImage} alt="Video Editing" 
                                style={{ 
                                    borderRadius: '20px', 
                                    height: 'auto', 
                                    width: '100%', 
                                    maxWidth: '700px', 
                                    objectFit: 'cover' 
                                  }} />
                            </div>
                        </Col>
                        <Col lg={5} data-aos="fade-right" data-aos-duration="1000" className="align-self-center">
                            <h3>Crafting Stories, Frame by Frame</h3>
                            <p className="mt-3">
                                Video editing is the art of weaving together moments captured in time, transforming raw footage into a tapestry of narrative brilliance. It encompasses a meticulous process of sculpting and refining, where each frame is carefully curated to evoke emotion, captivate audiences, and convey a compelling story. From the rhythmic dance of cuts and transitions to the harmonious blend of visual effects and audio enhancements, every element is orchestrated with precision to breathe life into the filmmaker's vision. Through the lens of video editing, storytellers wield the power to transport viewers to distant worlds, evoke laughter and tears, and inspire profound reflection. With each frame meticulously crafted, video editing transcends mere technical manipulation to become an art form that resonates with the human experience, leaving an indelible mark on hearts and minds, frame by frame.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="author-page1">
                <Container>
                    <Row>
                        <Col lg={12} data-aos="fade-up" data-aos-duration="1000">
                            <div className="section-heading" style={{marginTop:'5px'}}>
                                <h2 className="text-center">What We <em>offer</em></h2><br />
                                <div className="line-dec"></div>
                                <h5>
                                    Transform footage into cinematic masterpieces with IDA's video editing services. Our skilled editors craft compelling narratives that resonate with audiences, ensuring your story is told with clarity and impact, and creating memorable viewing experiences that leave a lasting impression on viewers, driving engagement and brand recognition.
                                </h5>
                            </div>
                        </Col>

                        <Col lg={3} md={6} data-aos="fade-down" data-aos-duration="1000">
                            <div className="item">
                                <div className="row">
                                    <Col lg={12}>
                                        <img src={levelPrototyping} alt="Professional Video Editing Services" 
                                        style={{ 
                                            borderRadius: '20px', 
                                            height: '248px', 
                                            width: '100%', 
                                            maxWidth: '700px',
                                            maxHeight: '700px', 
                                            objectFit: 'cover'
                                          }} />
                                        <h4 className="text-center">Professional Video Editing Services</h4>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="line-dec"></div>
                                        <div className="row">
                                            <h6>Transform your videos with our professional touch. From refining footage to adding effects, we ensure your content stands out.</h6>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </Col>

                        <Col lg={3} md={6} data-aos="fade-down" data-aos-duration="1000">
                            <div className="item">
                                <div className="row">
                                    <Col lg={12}>
                                        <img src={char} alt="Creative Storytelling Through Editing" 
                                        style={{ 
                                            borderRadius: '20px', 
                                            height: '248px', 
                                            width: '100%', 
                                            maxWidth: '700px',
                                            maxHeight: '700px', 
                                            objectFit: 'cover'
                                          }}/>
                                        <h4 className="text-center">Creative Storytelling Through Editing</h4>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="line-dec"></div>
                                        <div className="row">
                                            <h6>Craft compelling narratives through our editing expertise. With creative cuts and transitions.</h6>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </Col>

                        <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="1000">
                            <div className="item">
                                <div className="row">
                                    <Col lg={12}>
                                        <img src={asset} alt="Fast and Efficient Editing Solutions" 
                                        style={{ 
                                            borderRadius: '20px', 
                                            height: '248px', 
                                            width: '100%', 
                                            maxWidth: '700px',
                                            maxHeight: '700px', 
                                            objectFit: 'cover' 
                                          }}/>
                                        <h4 className="text-center">Fast and Efficient Editing Solutions</h4>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="line-dec"></div>
                                        <div className="row">
                                            <h6>Meet tight deadlines with ease. Our efficient workflow guarantees quick turnaround times without sacrificing quality.</h6>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </Col>

                        <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="1000">
                            <div className="item">
                                <div className="row">
                                    <Col lg={12}>
                                        <img src={inho} alt="Customized Editing for Your Needs" 
                                        style={{ 
                                            borderRadius: '20px', 
                                            height: '248px', 
                                            width: '100%', 
                                            maxWidth: '700px',
                                            maxHeight: '700px', 
                                            objectFit: 'cover' 
                                          }} />
                                        <h4 className="text-center">Customized Editing for Your Needs</h4>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="line-dec"></div>
                                        <div className="row">
                                            <h6>Tailored to fit your vision perfectly. We collaborate closely to understand your requirements and your Style matching.</h6>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="container1">
                        <div className="btn" style={{ fontWeight: 900 }}><Link to="/contact">Join Now</Link></div>
                    </div>
                </Container>
            </div>
            <Footer />
        </>
    );
};

export default VideoEditing;
