import React from 'react';
import { Link } from 'react-router-dom';
import './projects.css'

import jk from '../assets/images/img/jk.png';

function Project4() {
  return (
    <div className="item-details-page">
      <div className="container">
        <div className="row">
          <div data-aos="fade-up" data-aos-duration="2000" className="col-lg-12">
            <div className="section-heading">
              <h2 style={{ marginTop: '100px' }}>
                <br></br><br></br>
                Wordwide Artwork Ground - <em>Global Art Showcase Platform.</em>
              </h2>
              <div className="line-dec mt-2"></div>
            </div>
          </div>
          <div data-aos="fade-right" data-aos-duration="2000" className="col-lg-7">
            <div className="left-image">
              <img
                src={jk}
                alt="Wordwide Artwork Ground"
                style={{ 
                  borderRadius: '20px', 
                  height: 'auto', 
                  width: '100%', 
                  maxWidth: '700px', 
                  objectFit: 'cover' 
                }}
              />
            </div>
          </div>
          <div data-aos="fade-right" data-aos-duration="2000" className="col-lg-5 align-self-center">
            <h3>Inspire, Create, Share</h3>
            <p className="mt-3">
              Welcome to Wordwide Artwork Ground - Global Art Showcase Platform, where artists from around the world
              come together to inspire and be inspired. Showcase your artwork, connect with fellow artists, and
              celebrate creativity in all its forms!
            </p>
          </div>
        </div>
      </div>

      <div className="author-page">
        <div className="container">
          <div className="row">
            <div data-aos="fade-down" data-aos-duration="2000" className="col-lg-12">
              <div className="section-heading">
                <h2 className="text-center">
                  <br></br>
                  Key <em>Features</em>
                </h2>
                
                <div className="line-dec"></div>
                <ul>
                  <li>Artwork Showcase: Display your artwork to a global audience and gain recognition.</li>
                  <li>Artist Community: Connect with fellow artists, share techniques, and collaborate on projects.</li>
                  <li>Global Reach: Reach art enthusiasts from around the world and expand your audience.</li>
                  <li>Inspiration Hub: Explore diverse artworks, discover new styles, and get inspired.</li>
                  <li>Feedback & Critique: Receive valuable feedback and critique from peers to improve your craft.</li>
                  <li>Virtual Exhibitions: Participate in virtual exhibitions and showcase your work in curated collections.</li>
                </ul>
              </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="2000" className="col-lg-12">
              <div style={{ marginBottom: '10px' }} className="section-heading">
                <h2 className="text-center">
                  Get <em>Started</em>
                </h2>
               
                <div className="line-dec"></div>
                <h6>
                  Join the Wordwide Artwork Ground - Global Art Showcase Platform and unleash your creativity. Connect,
                  explore, and showcase your artwork to the world. Let your imagination soar!
                </h6>
              </div>
            </div>

            <div className="container1" style={{ marginTop: '20px' }}> {/* Added marginTop for spacing */}
              <div style={{ fontWeight: '900' }} className="btn">
                <Link to="/contact">Join Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Project4;
