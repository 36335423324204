import React, { useEffect, useRef, useState } from 'react';
//import '../vendor/bootstrap/css/bootstrap.min.css'; 
// import '../assets/css/fontawesome.css'; 
// import '../assets/css/templatemo-liberty-market.css'; 
import '../assets/css/owl.css'; 
import '../assets/css/animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../assets/css/index.css';
import './caro.css'
import vd from '../assets/images/img/project3.MP4';
import img1 from '../assets/images/main_banner.jpg';
import img2 from '../assets/images/v1.jpg';
import img3 from '../assets/images/cv.jpg'


function Carousel({ slides, activeIndex }) {
  return (
    <div className="carousel-container">
      {slides.map((slide, index) => {
        const position = index - activeIndex;
        let transformStyle = '';

        if (position === 0) {
          // Active slide (center/front)
          transformStyle = 'scale(1) translateX(0)';
        } else if (position === -1 || position === slides.length - 1) {
          // Left of active slide
          transformStyle = 'scale(0.9) translateX(-120%)';
        } else if (position === 1 || position === -slides.length + 1) {
          // Right of active slide
          transformStyle = 'scale(0.9) translateX(120%)';
        } else {
          // All other slides (hidden off-screen)
          transformStyle = 'scale(0.7) translateX(300%)';
        }

        return (
          <div
            key={index}
            className={`carousel-item ${index === activeIndex ? 'active' : ''}`}
            style={{ 
              transform: transformStyle, 
              zIndex: index === activeIndex ? 10 : 1,
              transition: 'transform 0.6s ease-in-out',  // Smooth transition for slides
            }}
          >
            <img src={slide.src} alt={slide.alt} />
          </div>
        );
      })}
    </div>
  );
}

const MainBanner = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const slides = [
    { src: img1, alt: 'Description 1' },
    { src: img2, alt: 'Description 2' },
    { src: img3, alt: 'Description 3' },
  ];

  useEffect(() => {
    // Set up the carousel interval for automatic slide change
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex < slides.length - 1 ? prevIndex + 1 : 0));
    }, 3000); // Slide changes every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [slides.length]);

  useEffect(() => {
    // Initialize AOS animations
    AOS.init();
  }, []);

  return (
    <div className="main-banner" id="header">
      <div className="banner-content">
        <h6 data-aos="fade-up" data-aos-duration="2500">
          Industrial Design & Animation
        </h6>
        <div className="centered-hr"></div>
        <h1 data-aos="fade-right" data-aos-duration="2500">
          Future of Interaction: Merging Physical and Digital Worlds<br />
          Through AR/VR Innovation
        </h1>
      </div>

      <div className="caro-container">
        <Carousel slides={slides} activeIndex={activeIndex} />
      </div>
    </div>
  );
};

export default MainBanner;