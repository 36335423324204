import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'aos/dist/aos.css';  // Make sure AOS (Animate On Scroll) is imported
import { Link } from 'react-router-dom';


// Importing images
import Footer from './Footer';
import academicImage from '../assets/images/my new/ad.jpeg';
import levelPrototyping from '../assets/images/my new/LevelProtoyping.png';
import char from '../assets/images/my new/char.png';
import asset from '../assets/images/my new/asset.png';
import inho from '../assets/images/my new/inho.png';

const Academic = () => {
    return (
        <>
            <div className="page-heading normal-space">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <br></br>
                            <h6>IDA Creative Studios</h6>
                            <h2>Academic Alliances</h2>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="item-details-page">
                <Container>
                    <Row>
                        <Col lg={12} data-aos="fade-up" data-aos-duration="1000">
                            <div className="section-heading">
                                <br></br><br></br>
                                <h2>Fostering knowledge, shaping the <em>future</em></h2>
                                <div className="line-dec mt-2"></div>
                            </div>
                        </Col>
                        <Col lg={7} data-aos="fade-left" data-aos-duration="1000">
                            <div className="left-image">
                                <img src={academicImage} alt="Academic Alliances" 
                                style={{ 
                                    borderRadius: '20px', 
                                    height: 'auto', 
                                    width: '100%', 
                                    maxWidth: '700px', 
                                    objectFit: 'cover' 
                                  }} />
                            </div>
                        </Col>
                        <Col lg={5} data-aos="fade-right" data-aos-duration="1000" className="align-self-center">
                            <h3>Fostering Innovation Together</h3>
                            <p className="mt-31" >
                                Academic alliances serve as catalysts for innovation, forging symbiotic partnerships between industry pioneers and educational institutions to propel research, talent development, and knowledge exchange to new heights. By seamlessly bridging the realms of academia and industry, these collaborations foster a dynamic ecosystem where groundbreaking ideas flourish and cutting-edge technologies are born. Through shared expertise and resources, academic alliances empower the next generation of innovators to tackle complex challenges and drive transformative change across diverse fields. Together, academia and industry unlock the potential for interdisciplinary collaboration, paving the way for visionary solutions that shape the future of our society and economy.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="author-page1">
                <Container>
                    <Row>
                        <Col lg={12} data-aos="fade-up" data-aos-duration="1000">
                            <div className="section-heading">
                                <h2 className="text-center">What We <em>offer</em></h2><br />
                                <div className="line-dec"></div>
                                <h5>
                                    Foster innovation with IDA's Academic Alliances program. Through strategic partnerships and research collaborations, we support academic initiatives that shape the future of technology and creativity, empowering the next generation of innovators to make a meaningful impact on society and drive positive change through groundbreaking research and education.
                                </h5>
                            </div>
                        </Col>
                        <Col lg={3} md={6} data-aos="fade-left" data-aos-duration="1000">
                            <div className="item">
                                <div className="row">
                                    <Col lg={12}>
                                        <img src={levelPrototyping} alt="Strategic Academic Partnerships" 
                                        style={{ 
                                            borderRadius: '20px', 
                                            height: '248px', 
                                            width: '100%', 
                                            maxWidth: '700px',
                                            maxHeight: '700px', 
                                            objectFit: 'cover' 
                                          }} />
                                        <h4 className="text-center">Strategic Academic Partnerships</h4>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="line-dec"></div>
                                        <div className="row">
                                          <h6>Build partnerships with universities. Together, we drive innovative development.</h6>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="1000">
                            <div className="item">
                                <div className="row">
                                    <Col lg={12}>
                                        <img src={char} alt="Research Collaboration Initiatives" 
                                        style={{ 
                                            borderRadius: '20px', 
                                            height: '248px', 
                                            width: '100%', 
                                            maxWidth: '700px',
                                            maxHeight: '700px', 
                                            objectFit: 'cover' 
                                          }}/>
                                        <h4 className="text-center">Research Collaboration Initiatives</h4>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="line-dec"></div>
                                        <div className="row">
                                            <h6>Join forces for research. Our collaborations lead to impactful discoveries.</h6>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6} data-aos="fade-down" data-aos-duration="1000">
                            <div className="item">
                                <div className="row">
                                    <Col lg={12}>
                                        <img src={asset} alt="Student Engagement Programs" 
                                        style={{ 
                                            borderRadius: '20px', 
                                            height: '248px', 
                                            width: '100%', 
                                            maxWidth: '700px',
                                            maxHeight: '700px', 
                                            objectFit: 'cover' 
                                          }} />
                                        <h4 className="text-center">Student Engagement Programs</h4>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="line-dec"></div>
                                        <div className="row">
                                            <h6>Empower students with programs. We offer hands-on experience on real-time environments and mentorship.</h6>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6} data-aos="fade-right" data-aos-duration="1000">
                            <div className="item">
                                <div className="row">
                                    <Col lg={12}>
                                        <img src={inho} alt="Educational Outreach and Support" 
                                        style={{ 
                                            borderRadius: '20px', 
                                            height: '248px', 
                                            width: '100%', 
                                            maxWidth: '700px',
                                            maxHeight: '700px', 
                                            objectFit: 'cover' 
                                          }} />
                                        <h4 className="text-center">Educational Outreach and Support</h4>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="line-dec"></div>
                                        <div className="row">
                                            <h6>Support education initiatives. We provide resources and expertise to enrich learning.</h6>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="container1">
                    <div style={{ fontWeight: 900 }} className="btn"><Link to="/contact">Join Now</Link></div>
                    </div>
                </Container>

            </div>
            <Footer/>
        </>
    );
};

export default Academic;
