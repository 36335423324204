import { Link } from 'react-router-dom';
import './carousel.css';
import '../vendor/bootstrap/css/bootstrap.min.css'; 
// import '../assets/css/fontawesome.css'; 
// import '../assets/css/templatemo-liberty-market.css'; 
import '../assets/css/owl.css'; 
// import '../assets/css/animate.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../assets/css/index.css';

// Import images directly
import bigEyeGlobal from '../assets/images/img/logos/bigeyeglobal.jpg';
import adcom from '../assets/images/img/logos/adcom.jpeg';
import iiec from '../assets/images/img/logos/1iiec.png';
import bltzwork from '../assets/images/img/logos/bltzwork.jpeg';
import commnet from '../assets/images/img/logos/commnet.jpeg';
import digitallyVibed from '../assets/images/img/logos/digitallyvibed.jpg';
import exatech from '../assets/images/img/logos/Exatech.jpg';
import wowHR from '../assets/images/img/logos/wowhr.jpg';
import oneEyes from '../assets/images/img/logos/oe.jpeg';
import vultureLines from '../assets/images/img/logos/vulturelines.jpg';

import game1 from '../assets/images/img/car.jpg';
import game2 from '../assets/images/img/shoot.jpg';
import game3 from '../assets/images/img/p3.jpeg';
import jk from '../assets/images/img/dt.jpg';
import vt from '../assets/images/img/vt.jpg';
import tw from '../assets/images/img/wt.jpeg';
import vto from '../assets/images/img/vto2.jpg';
import web from '../assets/images/img/web2.jpg'

const clientLogos = [
  { src: bigEyeGlobal, alt: 'Big Eye Global' },
  { src: adcom, alt: 'Adcom' },
  { src: iiec, alt: '1iiec' },
  { src: bltzwork, alt: 'Blitz Work' },
  { src: commnet, alt: 'Commnet' },
  { src: digitallyVibed, alt: 'Digitally Vibed' },
  { src: exatech, alt: 'Exatech' },
  { src: wowHR, alt: 'Wow HR' },
  
  
];


const Clients = () => {
    const clientOptions = {
        loop: true,
        margin: 30,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayTimeout: 3000,
        responsive: {
            0: {
                items: 2
            },
            600: {
                items: 4
            },
            1000: {
                items: 5
            }
        }
    };

    const projectOptions = {
        loop: true,
        margin: 10,
        nav: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 3000,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    };

    return (
        <div className="carousel-wrapper">
            {/* Our Clients Section */}
            <div className="categories-collections">
                <div className="container">
                    <div className="row">
                    <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-heading">
                                            <h2 data-aos="fade-up" data-aos-duration="2000">
                                                Our <em>Clients</em>
                                            </h2>
                                            <div className="line-dec mt-2"></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <OwlCarousel className="owl-theme owl-collection1 mt-5" {...clientOptions}>
                                            <div className="item">
                                                <div className="client-logo-container">
                                                    <img
                                                        src={bigEyeGlobal}
                                                        alt="BigEyeGlobal"
                                                        className="client-logo"
                                                    />
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="client-logo-container">
                                                    <img
                                                        src={adcom}
                                                        alt="Adcom"
                                                        className="client-logo"
                                                    />
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="client-logo-container">
                                                    <img
                                                        src={iiec}
                                                        alt="1IIEC"
                                                        className="client-logo"
                                                    />
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="client-logo-container">
                                                    <img
                                                        src={bltzwork}
                                                        alt="Blitzwork"
                                                        className="client-logo"
                                                    />
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="client-logo-container">
                                                    <img
                                                        src={commnet}
                                                        alt="Commnet"
                                                        className="client-logo"
                                                    />
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="client-logo-container">
                                                    <img
                                                        src={digitallyVibed}
                                                        alt="Digitally Vibed"
                                                        className="client-logo"
                                                    />
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="client-logo-container">
                                                    <img
                                                        src={exatech}
                                                        alt="Exatech"
                                                        className="client-logo"
                                                    />
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="client-logo-container">
                                                    <img
                                                        src={wowHR}
                                                        alt="WowHR"
                                                        className="client-logo"
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="item">
                                                <div className="client-logo-container">
                                                    <img
                                                        src={oneEyes}
                                                        alt="OneYes"
                                                        className="client-logo"
                                                    />
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="client-logo-container">
                                                    <img
                                                        src={vultureLines}
                                                        alt="VultureLines"
                                                        className="client-logo"
                                                    />
                                                </div>
                                            </div> */}
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                

                        {/* Some Of Our Projects Section */}
                        <div className="container">
                        <div className="col-lg-12">
                            <div className="collections">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-heading">
                                            <h2 data-aos="fade-up" data-aos-duration="1000">Some Of Our <em>Projects</em></h2>
                                            <div className="line-dec mt-2"></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <OwlCarousel className="owl-theme owl-collection mt-5" {...projectOptions}>
                                            <div className="item">
                                                <img src={game1} alt="Turbo Thrust" style={{objectFit: 'cover'}}/>
                                                <div className="down-content">
                                                    <h4 className="text-center">Turbo Thrust</h4>
                                                    <div className="main-button">
                                                        <Link to="/project1">Mobile Racing Game</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <img src={game2} alt="Precision Strike" />
                                                <div className="down-content">
                                                    <h4 className="text-center">Precision Strike</h4>
                                                    <div className="main-button">
                                                        <Link to="/project2">Mobile Shooting Game</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <img src={game3} alt="Startup Fest Expo" style={{objectFit: 'cover'}} />
                                                <div className="down-content">
                                                    <h4 className="text-center">Startup Fest Expo</h4>
                                                    <div className="main-button">
                                                        <Link to="/project3">OneYes Startup Expo</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <img src={jk} alt="Worldwide Artwork Ground" style={{objectFit: 'cover'}}/>
                                                <div className="down-content">
                                                    <h4 className="text-center">Worldwide Artwork Ground</h4>
                                                    <div className="main-button">
                                                        <Link to="/project4">Global Art Showcase Platform</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <img src={vt} alt="Virtual Try-On" style={{objectFit: 'cover'}}/>
                                                <div className="down-content">
                                                    <h4 className="text-center">Virtual Try-On</h4>
                                                    <div className="main-button">
                                                        <Link to="/project5">Augmented Reality in Wholesale Fashion</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <img src={tw} alt="3d Walkthrough" style={{objectFit: 'cover'}} />
                                                <div className="down-content">
                                                    <h4 className="text-center">3D Walkthrough</h4>
                                                    <div className="main-button">
                                                    <Link to="/project6">Discover Our Immersive 3D Walkthrough</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <img src={vto} alt="Worldwide Artwork Ground" style={{objectFit: 'cover'}} />
                                                <div className="down-content">
                                                    <h4 className="text-center">Virtual Tour</h4>
                                                    <div className="main-button">
                                                    <Link to="/project7">Discover 3D Virtual Journeys</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <img src={web} alt="Worldwide Artwork Ground" style={{objectFit: 'cover'}}/>
                                                <div className="down-content">
                                                    <h4 className="text-center">Web XR</h4>
                                                    <div className="main-button">
                                                    <Link to="/project8">Explore the Future of Web XR</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                
            
        </div>
    );
}

export default Clients;

