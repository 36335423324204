import React from 'react';
import { Link } from 'react-router-dom';
import image1 from '../assets/images/my new/VFX-FB.jpg';
import image2 from '../assets/images/my new/LevelProtoyping.png';
import image3 from '../assets/images/my new/char.png';
import image4 from '../assets/images/my new/asset.png';
import image5 from '../assets/images/my new/inho.png';
import Footer from './Footer';


const Vfx = () => {
  return (
    <>
      <div className="page-heading normal-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <br></br>
              <h6>IDA Creative Studios</h6>
              <h2>VFX</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="item-details-page">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
              <div className="section-heading">
                <h2 className="mt-5">Bringing imagination to <em>life</em></h2>
                <div className="line-dec mt-2"></div>
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-7">
              <div className="left-image">
              <img 
               src={image1} 
               alt="3D Modeling" 
               style={{ 
                 borderRadius: '20px', 
                 height: 'auto', 
                 width: '100%', 
                 maxWidth: '700px', 
                 objectFit: 'cover' 
               }} 
             />

              </div>
            </div>
            <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-5 align-self-center">
              <h3>Visions, Now Reality</h3>
              <p className="mt-3">
                Visual effects (VFX) stand as the alchemical fusion of artistry and technology, where boundless imagination meets cutting-edge innovation to redefine the boundaries of visual storytelling. From the awe-inspiring spectacles of otherworldly realms to the subtle nuances that breathe life into fantastical creatures, VFX wield the power to transport audiences to realms beyond the limits of reality. Whether crafting epic battles on distant planets or seamlessly integrating digital creations into live-action footage, VFX artists serve as the architects of wonder, sculpting immersive worlds and breathing life into the impossible. Through a symphony of digital wizardry and practical craftsmanship, VFX transform mere visions into tangible reality, weaving dreams into the fabric of cinematic experience with unparalleled mastery.

              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="author-page1">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
              <div className="section-heading">
                <h2 className="text-center">What We <em>offer</em></h2>
                <br />
                <div className="line-dec"></div>
                <h5>
                 Transport viewers to fantastical worlds with IDA's visual effects expertise. Our VFX artists deliver awe-inspiring visuals that leave a lasting impact, bringing cinematic magic to your projects and enhancing the overall viewing experience with seamless integration of stunning effects and realistic simulations.
                </h5>
              </div>
            </div>

            <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={image2} alt="Post-production & Compositing" 
                    style={{ 
                      borderRadius: '20px', 
                      height: '248px', 
                      width: '100%', 
                      maxWidth: '700px',
                      maxHeight: '700px', 
                      objectFit: 'cover' 
                    }}
                    />
                    <h4 className="text-center">Post-production & Compositing</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>
                        Refine your visuals with expert post-production. Enhance your content through seamless editing and layering.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-down" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={image3} alt="Conceptualization & Storyboarding"  
                    style={{ 
                      borderRadius: '20px', 
                      height: '248px', 
                      width: '100%', 
                      maxWidth: '700px',
                      maxHeight: '700px', 
                      objectFit: 'cover' 
                    }}
                    />
                    <h4 className="text-center">Conceptualization & Storyboarding</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>
                      Bring ideas to life with clear direction. Visualize your concepts through detailed storyboards, setting the stage for your project.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={image4} alt="VFX Design & Animation"
                    style={{ 
                      borderRadius: '20px', 
                      height: '248px', 
                      width: '100%', 
                      maxWidth: '700px',
                      maxHeight: '700px', 
                      objectFit: 'cover' 
                    }}  
                    />
                    <h4 className="text-center">VFX Design & Animation</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>
                      Add magic to your visuals with professional VFX design. From stunning cinematic effects to captivating animations, we'll make your project truly shine.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={image5} alt="Asset Creation & Rendering"
                    style={{ 
                      borderRadius: '20px', 
                      height: '248px', 
                      width: '100%', 
                      maxWidth: '700px',
                      maxHeight: '700px', 
                      objectFit: 'cover' 
                    }}
                     />
                    <h4 className="text-center">Asset Creation & Rendering</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>
                      Build your immersive world from scratch with our asset creation. From models to detailed textures, we'll bring your vision to life.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container1">
            <div style={{ fontWeight: 900 }} className="btn"><Link to ="/contact">Join Now</Link></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Vfx;
