import React from 'react';
import { Link } from 'react-router-dom';
import computerGraphicsImage from '../assets/images/my new/computer-graphics-definition.png';
import levelPrototypingImage from '../assets/images/my new/LevelProtoyping.png';
import charImage from '../assets/images/my new/char.png';
import assetImage from '../assets/images/my new/asset.png';
import inhoImage from '../assets/images/my new/inho.png';
import Footer from './Footer';

const Comgraphic = () => {
  return (
    <div>
      <div className="page-heading normal-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <br></br>
              <h6>IDA Creative Studios</h6>
              <h2>Computer Graphics</h2>
              {/* <span>Home > <a href="details.html">Services ></a> <a href="#">CG</a></span> */}
            </div>
          </div>
        </div>
      </div>

      <div className="item-details-page">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" data-aos-duration="2000" className="col-lg-12">
              <div className="section-heading">
                <br></br><br></br>
                <h2>Crafting visuals, shaping <em>perceptions</em></h2>
                <div className="line-dec mt-2"></div>
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-7">
              <div className="left-image">
                <img src={computerGraphicsImage} alt="Computer Graphics" 
                style={{ 
                  borderRadius: '20px', 
                  height: 'auto', 
                  width: '100%', 
                  maxWidth: '700px', 
                  objectFit: 'cover' 
                }} />
              </div>
            </div>
            <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-5 align-self-center">
              <h3>Where Imagination Meets Reality</h3>
              <p className="mt-3">
                Computer graphics (CG) stands as the quintessential bridge between the ethereal realm of imagination and the palpable tangibility of reality. With the stroke of a digital brush, CG artisans sculpt worlds that transcend the constraints of physicality, breathing life into fantastical realms and mundane landscapes alike. From the sprawling vistas of distant galaxies to the intricate details of microscopic organisms, CG unfolds a canvas upon which the depths of human imagination are brought vividly to life. Across diverse industries spanning film, advertising, architecture, and gaming, CG serves as the cornerstone of visual storytelling, enriching narratives with breathtaking vistas, awe-inspiring special effects, and dynamic animations.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="author-page1">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
              <div className="section-heading">
                <h2 className="text-center">What We <em>offer</em></h2>
                <br />
                <div className="line-dec"></div>
                <h5>
                  Explore visual storytelling with IDA's CG services. Our talented artists create awe-inspiring visuals that captivate audiences, pushing the boundaries of creativity and innovation, and elevating your content with stunning graphics and lifelike animations that leave a lasting impression on viewers, setting your brand apart from the competition.
                </h5>
                <p></p>
              </div>
            </div>

            <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    
                    <img src={levelPrototypingImage} alt="High-Quality CG Rendering" 
                    style={{ 
                      borderRadius: '20px', 
                      height: '248px', 
                      width: '100%', 
                      maxWidth: '700px',
                      maxHeight: '700px', 
                      objectFit: 'cover' 
                    }}/>
                    <h4 className="text-center">High-Quality CG Rendering</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>Experience realistic visuals with our CG rendering. We bring concepts to life with detail and accuracy.</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-down" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    
                    <img src={charImage} alt="Custom CG Animation" 
                    style={{ 
                      borderRadius: '20px', 
                      height: '248px', 
                      width: '100%', 
                      maxWidth: '700px',
                      maxHeight: '700px', 
                      objectFit: 'cover' 
                    }} />
                    <h4 className="text-center">Custom CG Animation</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>Tell stories with engaging CG animation. Our custom animations captivate audiences and convey your message.</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    
                    <img src={assetImage} alt="CG Modeling and Texturing" 
                   style={{ 
                    borderRadius: '20px', 
                    height: '248px', 
                    width: '100%', 
                    maxWidth: '700px',
                    maxHeight: '700px', 
                    objectFit: 'cover' 
                  }} />
                    <h4 className="text-center">CG Modeling and Texturing</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>Create lifelike designs with our CG modeling and texturing. We ensure every detail is visually stunning</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    
                    <img src={inhoImage} alt="Real-Time CG Solutions" 
                    style={{ 
                      borderRadius: '20px', 
                      height: '248px', 
                      width: '100%', 
                      maxWidth: '700px',
                      maxHeight: '700px', 
                      objectFit: 'cover' 
                    }} />
                    <h4 className="text-center">Real-Time CG Solutions</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>Explore interactive experiences with our real-time CG solutions. Our technology delivers seamless and immersive visuals</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container1">
              <div style={{ fontWeight: '900' }} className="btn">
                <Link to ="/contact">Join Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Comgraphic;
