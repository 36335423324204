import React from 'react';
import '../vendor/bootstrap/css/bootstrap.min.css'; 
// import '../assets/css/fontawesome.css'; 
// import '../assets/css/templatemo-liberty-market.css'; 
// import '../assets/css/owl.css'; 
// import '../assets/css/animate.css';
import '../assets/css/index.css';

import meetingImage from '../assets/images/my new/u.gif';
import lightBulbImage from '../assets/images/my new/l.gif';
import automationImage from '../assets/images/my new/e.gif';

const WorkProcess = () => {
  return (
    <div className="create-nft">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="section-heading">
              <h2 data-aos="fade-right">Our Work Process.</h2>
              <div className="line-dec"></div>
            </div>
          </div>
          <div data-aos="fade-left" className="col-lg-4">
            {/* This column is empty, you can remove it or add content */}
          </div>
          <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-4">
            <div className="item first-item">
              <div className="number">
                {/* <h6>1</h6> */}
              </div>
              <div className="icon">
                <img src={meetingImage} alt="Discussion" />
              </div>
              <h4>Discussion</h4>
              <p>Under the client's business, goals and challenges for building relationship.</p>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-4">
            <div className="item second-item">
              <div className="number">
                {/* <h6>2</h6> */}
              </div>
              <div className="icon">
                <img src={lightBulbImage} alt="Ideate" />
              </div>
              <h4>Ideate</h4>
              <p>Gather ideas and create the first concept for the future product.</p>
            </div>
          </div>
          <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-4">
            <div className="item">
              <div className="icon3">
                <img src={automationImage} alt="Execution" />
              </div>
              <h4>Execution</h4>
              <p>Provide the first draft of a project, accept minor and major revisions.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkProcess;