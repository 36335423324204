import React from 'react';
import { Link } from 'react-router-dom';
import image1 from '../assets/images/my new/genah.png';
import image2 from '../assets/images/my new/mot.png';
import image3 from '../assets/images/my new/ComfyUI_00482_.png';
import image4 from '../assets/images/my new/VP.png';
import image5 from '../assets/images/my new/Prompt.webp';
import Footer from './Footer';


function GenAi() {
  return (
    <>
      <div className="page-heading normal-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <br></br>
              <h6>IDA Creative Studios</h6>
              <h2>Generative AI</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="item-details-page">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" className="col-lg-12">
              <div className="section-heading">
                <br></br><br></br>
                <h2>Innovation powered by <em>AI creativity.</em></h2>
                <div className="line-dec mt-2"></div>
              </div>
            </div>
            <div data-aos="fade-left" className="col-lg-7">
              <div className="left-image">
                <img src={image1} alt="Generative AI"  
                style={{ 
                  borderRadius: '20px', 
                  height: 'auto', 
                  width: '100%', 
                  maxWidth: '700px', 
                  objectFit: 'cover' 
                }}   />
              </div>
            </div>
            <div data-aos="fade-right" className="col-lg-5 align-self-center">
              <h3>AI, Unleash Creativity.</h3>
              <p className="mt-3">
                Generative AI represents a groundbreaking frontier in artificial intelligence, empowering systems with the remarkable ability to autonomously conceive and fabricate an array of content spanning images, music, text, and complete designs. At its core, these advanced systems harness the power of machine learning algorithms to discern intricate patterns from vast repositories of existing data, extracting nuanced insights to inform the generation of fresh, innovative outputs. Through iterative learning and refinement, generative AI transcends mere replication, sparking the emergence of entirely novel creations that push the boundaries of creativity and redefine the possibilities of digital expression.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="author-page1">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" data-aos-duration="1000" className="section-heading"  style={{marginTop:'5px'}}  >
              <h2 className="text-center">What We <em>offer</em></h2>
              <br />
              <div className="line-dec"></div>
              <h5>
                Unlock creativity with IDA's Generative AI solutions. Our advanced algorithms empower businesses to automate content creation and explore new creative horizons, revolutionizing industries and unlocking endless possibilities for innovation and growth.
              </h5>
              <p></p>
            </div>
            
              <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-3 col-md-6">
                <div className="item">
                  <div className="row">
                    <div className="col-lg-12">
                      <img src={image2} alt="Image Diffusers" 
                       style={{ 
                        borderRadius: '20px', 
                        height: '248px', 
                        width: '100%', 
                        maxWidth: '700px',
                        maxHeight: '700px', 
                        objectFit: 'cover'  
                      }}  />
                      <h4 className="text-center">Image Diffusers</h4>
                    </div>
                    <div className="col-lg-12">
                      <div className="line-dec"></div>
                      <h6>Make images come to life with Image Diffusers. Experience augmented reality like never before.</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 col-md-6">
                <div className="item">
                  <div className="row">
                    <div className="col-lg-12">
                      <img src={image3} alt="Generative Fills" 
                       style={{ 
                        borderRadius: '20px', 
                        height: '248px', 
                        width: '100%', 
                        maxWidth: '700px',
                        maxHeight: '700px', 
                        objectFit: 'cover'  
                      }}  
                       />
                      <h4 className="text-center">Generative Fills</h4>
                    </div>
                    <div className="col-lg-12">
                      <div className="line-dec"></div>
                      <h6>Watch dynamic patterns unfold in real-time with Generative Fills. Add a unique touch to your projects.</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div data-aos="fade-down" data-aos-duration="1000" className="col-lg-3 col-md-6">
                <div className="item">
                  <div className="row">
                    <div className="col-lg-12">
                      <img src={image4} alt="AI Content Generation" 
                         style={{ 
                          borderRadius: '20px', 
                          height: '248px', 
                          width: '100%', 
                          maxWidth: '700px',
                          maxHeight: '700px', 
                          objectFit: 'cover'  
                        }}
                       />
                      <h4 className="text-center">AI Content Generation</h4>
                    </div>
                    <div className="col-lg-12">
                      <div className="line-dec"></div>
                      <h6>Let AI do the work for you. Create articles and visuals effortlessly with Artificial Intelligence.</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div data-aos="fade-down" data-aos-duration="1000" className="col-lg-3 col-md-6">
                <div className="item">
                  <div className="row">
                    <div className="col-lg-12">
                      <img src={image5} alt="Prompt Engineering" 
                       style={{ 
                        borderRadius: '20px', 
                        height: '248px', 
                        width: '100%', 
                        maxWidth: '700px',
                        maxHeight: '700px', 
                        objectFit: 'cover' 
                      }}  
                       
                       />
                      <h4 className="text-center">Prompt Engineering</h4>
                    </div>
                    <div className="col-lg-12">
                      <div className="line-dec"></div>
                      <h6>Craft compelling stories easily with Prompt Engineering. Guide users through immersive experiences.</h6>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div data-aos="fade-right" data-aos-duration="2000" className="col-lg-3 col-md-6">
                <div className="item">
                  <div className="row">
                    <div className="col-lg-12">
                      <img src={image5} alt="Prompt Engineering" 
                       style={{ 
                        borderRadius: '20px', 
                        height: 'auto', 
                        width: '100%', 
                        maxWidth: '700px', 
                        objectFit: 'cover' 
                      }}  
                       />
                      <h4 className="text-center">Prompt Engineering</h4>
                    </div>
                    <div className="col-lg-12">
                      <div className="line-dec"></div>
                      <h6>Craft compelling stories easily with Prompt Engineering. Guide users through immersive experiences.</h6>
                    </div>
                  </div>
                </div>
              </div> */}
            
            <div className="container1">
              <div style={{ fontWeight: '900' }} className="btn"><Link to = "/contact">Join Now</Link></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default GenAi;
