import React from 'react';
import fullSailImage from '../assets/images/my new/full-sail-to-build-virtual-production-studio-hero.jpg';
import levelPrototypingImage from '../assets/images/my new/LevelProtoyping.png';
import charImage from '../assets/images/my new/char.png';
import assetImage from '../assets/images/my new/asset.png';
import inhoImage from '../assets/images/my new/inho.png';
import Footer from './Footer';

const Virtualprod = () => {
  return (
    <div>
      <div className="page-heading normal-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <br></br>
              <h6>IDA Creative Studios</h6>
              <h2>Virtual Production</h2>
              {/* <span>Home > <a href="details.html">Services ></a> <a href="#">Virtual Production</a></span> */}
            </div>
          </div>
        </div>
      </div>

      <div className="item-details-page">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
              <div className="section-heading">
                <br></br><br></br>
                <h2>Reality reimagined, <em>virtually.</em></h2>
                <div className="line-dec mt-2"></div>
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-7">
              <div className="left-image">
                <img src={fullSailImage} alt="Virtual Production" 
                style={{ 
                  borderRadius: '20px', 
                  height: 'auto', 
                  width: '100%', 
                  maxWidth: '700px', 
                  objectFit: 'cover' 
                }}
                 />
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-5 align-self-center">
              <h3>Create Beyond Boundaries</h3>
              <p className="mt-3">
                Virtual production stands at the forefront of cinematic innovation, forging a new frontier where creativity knows no bounds. Harnessing the power of digital technology, filmmakers transcend the limitations of physical sets and locations, crafting immersive worlds that exist only within the realm of imagination. With precision and artistry, virtual production blurs the lines between reality and fiction, allowing directors to visualize scenes, sculpt lighting, and orchestrate visual effects in real-time. By seamlessly integrating live-action footage with virtual elements, filmmakers unlock a symphony of creative possibilities, sculpting narratives that defy convention and transport audiences to realms beyond the constraints of the tangible world.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="author-page1">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
              <div className="section-heading">
                <h2 className="text-center">What We <em>offer</em></h2>
                <br />
                <div className="line-dec"></div>
                <h5>
                  Redefine filmmaking with IDA's virtual production services. Our cutting-edge technology and creative expertise streamline workflows, enhance collaboration, and push the boundaries of visual storytelling, enabling filmmakers to bring their vision to life in unprecedented ways, revolutionizing the way stories are told and experienced.
                </h5>
                <p></p>
              </div>
            </div>

            <div data-aos="fade-down" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    
                    <img src={levelPrototypingImage} alt="Immersive Virtual Sets" 
                     style={{ 
                      borderRadius: '20px', 
                      height: '248px', 
                      width: '100%', 
                      maxWidth: '700px',
                      maxHeight: '700px', 
                      objectFit: 'cover' 
                    }}/> 
                    <h4 className="text-center">Immersive Virtual Sets</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>Explore limitless settings with our virtual sets. From movies to corporate videos, we transport you anywhere.</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-down" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    
                    <img src={charImage} alt="Real-Time Visualization" 
                     style={{ 
                      borderRadius: '20px', 
                      height: '248px', 
                      width: '100%', 
                      maxWidth: '700px',
                      maxHeight: '700px', 
                      objectFit: 'cover' 
                    }} />
                    <h4 className="text-center">Real-Time Visualization</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>With our cutting-edge technology, you can visualize and control every detail as you create real time environments.</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    
                    <img src={assetImage} alt="Virtual Cinematography Tools" 
                     style={{ 
                      borderRadius: '20px', 
                      height: '248px', 
                      width: '100%', 
                      maxWidth: '700px',
                      maxHeight: '700px', 
                      objectFit: 'cover' 
                    }}/>
                    <h4 className="text-center">Virtual Cinematography Tools</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>Capture perfect shots effortlessly. Our tools ensure precise camera movements in virtual environments.</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    
                    <img src={inhoImage} alt="Collaborative Virtual Workflows" 
                     style={{ 
                      borderRadius: '20px', 
                      height: '248px', 
                      width: '100%', 
                      maxWidth: '700px',
                      maxHeight: '700px', 
                      objectFit: 'cover' 
                    }}/>
                    <h4 className="text-center">Virtual Workflows</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>Work together seamlessly from anywhere. Our platform enhances teamwork throughout production.</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container1">
              <div style={{ fontWeight: '900' }} className="btn">
                <a href="/contact">Join Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Virtualprod;
