import React from 'react';
import { Link } from 'react-router-dom';
import hgfImage from '../assets/images/my new/hgf.png';
import prototype from '../assets/images/my new/LevelProtoyping.png';  // Adjust the path according to your structure
import char from '../assets/images/my new/char.png';
import asset from '../assets/images/my new/asset.png';
import inho from '../assets/images/my new/inho.png';
import Footer from './Footer';

import '../assets/css/index.css';

const Gamedev = () => {
  return (
    <>
      <div className="page-heading normal-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <br></br>
              <h6>IDA Creative Studios</h6>
              <h2>Game Development</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="item-details-page">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" className="col-lg-12">
              <div className="section-heading">
                 <br></br>
                <h2>Level up your <em>entertainment.</em></h2>
                
                <div className="line-dec mt-2"></div>
              </div>
            </div>
            <div data-aos="fade-left" className="col-lg-7">
              <div className="left-image">
                <img 
                  src={hgfImage} 
                  alt="Game Development" 
                  style={{ 
                    borderRadius: '20px', 
                    height: 'auto', 
                    width: '100%', 
                    maxWidth: '700px', 
                    objectFit: 'cover' 
                  }}  
                />
              </div>
            </div>
            <div data-aos="fade-left" className="col-lg-5 align-self-center">
              <h3>Play, Create, Conquer</h3>
              <p className="mt-3">
                Game development is a multifaceted journey that entails the meticulous crafting of interactive digital experiences, tailored to captivate audiences for entertainment or educational endeavors. It navigates through a diverse spectrum of stages, commencing with the inception of conceptual ideas, delving deep into the intricacies of character design, the meticulous construction of immersive levels, the intricate weaving of code through programming languages, culminating in exhaustive rounds of rigorous testing to ensure seamless functionality and optimal user experience. This comprehensive process encapsulates the fusion of artistry and technology, where creativity converges with technical prowess to breathe life into virtual worlds, fostering engagement, learning, and entertainment.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="author-page1">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" className="col-lg-12">
              <div className="section-heading">
                <h2 className="text-center">What We <em>offer</em></h2>
                <div className="line-dec mt-3"></div>
                <h5>
                  From concept to launch, IDA specializes in crafting immersive gaming experiences tailored to your vision. Our expert team brings ideas to life with meticulous attention to detail, ensuring captivating gameplay and unforgettable adventures that leave players craving for more.
                </h5>
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="2000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img 
                      src={prototype} 
                      alt="Level Designing & Prototyping" 
                      style={{ 
                        borderRadius: '20px', 
                        height: '248px', 
                        width: '100%', 
                        maxWidth: '700px',
                        maxHeight: '700px', 
                        objectFit: 'cover'  
                      }}/>
                    <h4 className="text-center">Level Designing & Prototyping</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <h6>Creating Stunning Level Environments for Your Games to Captivate Players and Enhance Immersion.</h6>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img 
                      src={char}
                      alt="Character Design & Development" 
                      style={{ 
                        borderRadius: '20px', 
                        height: '248px', 
                        width: '100%', 
                        maxWidth: '700px',
                        maxHeight: '700px', 
                        objectFit: 'cover'  
                      }}  
                    />
                    <h4 className="text-center">Character Design & Development</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <h6>Designing Realistic Characters & Motion for those Characters to Enhance Player Connection.</h6>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="fade-down" data-aos-duration="2000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img 
                      src={asset} 
                      alt="Asset Creation & Building" 
                      style={{ 
                        borderRadius: '20px', 
                        height: '248px', 
                        width: '100%', 
                        maxWidth: '700px',
                        maxHeight: '700px', 
                        objectFit: 'cover'  
                      }}
                    />
                    <h4 className="text-center">Asset Creation <br />& Building</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <h6>Creating Photorealistic Assets for your Games & Making More Optimized Assets</h6>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="fade-right" data-aos-duration="2000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    <img 
                      src={inho}
                      alt="Inhouse Game Productions" 
                      style={{ 
                        borderRadius: '20px', 
                        height: '248px', 
                        width: '100%', 
                        maxWidth: '700px',
                        maxHeight: '700px', 
                        objectFit: 'cover'  
                      }}  
                    />
                    <h4 className="text-center">Inhouse Game <br />Productions</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <h6>Making IDA's Own Inhouse Game Production Includes Designing and Developing Indie Games</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="container1">
              <div style={{ fontWeight: '900' }} className="btn">
                <Link to = "/contact">Join Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Gamedev;
