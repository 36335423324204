import React from 'react';
import './preloader.css'; 
import logo from '../assets/images/img/IDA.png'; // Adjust the path to your logo

const Preloader = () => {
  return (
    <div className="preloader-container">
      <img src={logo} alt="Loading..." className="preloader-logo" />
    </div>
  );
};

// function Preloader() {
//   return (
//     <div id="js-preloader" className="js-preloader">
//       <div className="preloader-inner">
//         <span className="dot"></span>
//         <div className="dots">
//           <span></span>
//           <span></span>
//           <span></span>
//         </div>
//       </div>
//     </div>
//   );
// }

export default Preloader;
