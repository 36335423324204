import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'aos/dist/aos.css'; // Include AOS styles
import AOS from 'aos'; // Import AOS library
import Footer from './Footer';

// Importing images
import image1 from '../assets/images/my new/3D-motion-to-2D.jpg';
import image2 from '../assets/images/my new/mot.png';
import image3 from '../assets/images/my new/9z5muo6h.jpg';
import image4 from '../assets/images/my new/Animation-process-1-800x504.png';
import image5 from '../assets/images/my new/gfsd.jpg';

AOS.init(); // Initialize AOS

const TwoDmodel = () => {
  return (
    <>
      <div className="page-heading normal-space">
        <Container>
          <Row>
            <Col lg={12}>
              <br></br>
              <h6>IDA Creative Studios</h6>
              <h2>2D/3D Animation</h2>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="item-details-page">
        <Container>
          <Row>
            <Col lg={12} data-aos="fade-up">
              <div className="section-heading">
                <br></br><br></br>
                <h2>Animating dreams into <em>reality</em></h2>
                <div className="line-dec mt-2"></div>
              </div>
            </Col>
            <Col lg={7} data-aos="fade-left" data-aos-duration="2000">
              <div className="left-image">
                <img 
                  src={image1} 
                  alt="3D Motion to 2D" 
                  style={{ borderRadius: '20px', height: '400px' }}
                />
              </div>
            </Col>
            <Col lg={5} data-aos="fade-right" data-aos-duration="2000" className="align-self-center">
              <h3>Animating Reality</h3>
              <p className="mt-3">
                Animation, the artful orchestration of movement, transcends the boundaries of static imagery, 
                imbuing life into every frame with a symphony of motion and emotion. In the realm of 2D 
                animation, artists craft fluid movements within the constraints of two-dimensional space, 
                employing techniques honed over generations to evoke laughter, tears, and wonder. Meanwhile, 
                3D animation ventures into a realm of boundless possibility, where characters and environments 
                traverse the depths of three-dimensional landscapes, captivating audiences with immersive 
                storytelling in mediums ranging from blockbuster films to interactive video games and captivating 
                advertisements. Meanwhile, stop-motion animation adds a tactile dimension to storytelling, 
                meticulously crafting worlds.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="author-page1">
        <Container>
          <Row>
            <Col lg={12} data-aos="fade-up" data-aos-duration="2000">
              <div className="section-heading" style={{marginTop:'5px'}}>
                <h2 className="text-center">What We <em>offer</em></h2><br />
                <div className="line-dec"></div>
                <h5>
                  IDA brings stories to life with captivating 2D and 3D animations. Our talented animators 
                  breathe life into characters and environments, delivering compelling narratives that resonate 
                  with audiences on an emotional level, creating memorable experiences that leave a lasting 
                  impression.
                </h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={3} md={6}>
              <div className="item">
                <Row>
                  <Col lg={12} data-aos="fade-left" data-aos-duration="2000">
                    <img 
                      src={image2} 
                      alt="2D Brand Illustrations" 
                      style={{ 
                        borderRadius: '20px', 
                        height: '248px', 
                        width: '100%', 
                        maxWidth: '700px',
                        maxHeight: '700px', 
                        objectFit: 'cover' 
                      }}
                    />
                    <h4 className="text-center">2D brand illustrations</h4>
                  </Col>
                  <Col lg={12}>
                    <div className="line-dec"></div>
                    <h6>Boost your brand with captivating 2D illustrations. Elevate your identity with visuals that leave a lasting impression.</h6>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="item">
                <Row>
                  <Col lg={12} data-aos="fade-up" data-aos-duration="2000">
                    <img 
                      src={image3} 
                      alt="3D Animated Videos" 
                      style={{ 
                        borderRadius: '20px', 
                        height: '248px', 
                        width: '100%', 
                        maxWidth: '700px',
                        maxHeight: '700px', 
                        objectFit: 'cover' 
                      }}
                    />
                    <h4 className="text-center">3D animated videos</h4>
                  </Col>
                  <Col lg={12}>
                    <div className="line-dec"></div>
                    <h6>Tell your story in dynamic 3D. Engage your audience with animated videos that bring ideas to life.</h6>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="item">
                <Row>
                  <Col lg={12} data-aos="fade-down" data-aos-duration="2000">
                    <img 
                      src={image4} 
                      alt="2D Process Animation" 
                      style={{ 
                        borderRadius: '20px', 
                        height: '248px', 
                        width: '100%', 
                        maxWidth: '700px',
                        maxHeight: '700px', 
                        objectFit: 'cover' 
                      }}
                    />
                    <h4 className="text-center">2D process animation</h4>
                  </Col>
                  <Col lg={12}>
                    <div className="line-dec"></div>
                    <h6>Simplify complex concepts with 2D animations. Guide your audience through processes with clear and engaging visuals.</h6>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="item">
                <Row>
                  <Col lg={12} data-aos="fade-right" data-aos-duration="2000">
                    <img 
                      src={image5} 
                      alt="2D & 3D Education Videos" 
                      style={{ 
                        borderRadius: '20px', 
                        height: '248px', 
                        width: '100%', 
                        maxWidth: '700px',
                        maxHeight: '700px', 
                        objectFit: 'cover' 
                      }}
                    />
                    <h4 className="text-center">2D & 3D Education videos</h4>
                  </Col>
                  <Col lg={12}>
                    <div className="line-dec"></div>
                    <h6>Educate and inspire with our diverse video offerings. From 2D explanations to immersive 3D experiences.</h6>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <div className="container1">
            <div className="btn" style={{ fontWeight: 900 }}>
              <a href="/contact">Join Now</a>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default TwoDmodel;
