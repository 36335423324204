import React from 'react';
import Footer from './Footer';
import createYours from '../assets/images/create-yours.jpg'; // Adjust the path as needed
import meetingImage from '../assets/images/my new/u.gif';
import lightBulbImage from '../assets/images/my new/l.gif';
import automationImage from '../assets/images/my new/e.gif';


const Aboutus = () => {
  return (
    <div>
      <div className="item-details-page">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" className="col-lg-12">
              <div style={{ marginTop: '130px' }} className="section-heading">
                <br></br>
                <h2>About <em>IDA</em></h2>
                <div className="line-dec mt-2"></div>
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-7">
              <div className="left-image">
                <img src={createYours} alt="" style={{ borderRadius: '20px', height: '430px', objectFit:'cover' }} />
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-5 align-self-center">
              <h3>A Next Step to Your Digital Reality</h3>
              <p className="mt-3">
                We IDA - Industrial Design and Animation is a creative and Innovative Digital Retransformation organisation with Focus the stream on both Web3.0 and Industry 4.0 solutions. We Transform and elevate your Industry and Business With Immersive Technical solutions with an extensive and wide range of Imagination that becomes Possible.
                <br />
                We @IDA Transform your needs and reinvent the Your business for the upcoming digital future. We create and design based on your needs with technologies like AR(Augmented Reality),
                VR(Virtual Reality), MR(Mixed Reality) and XR(EXtended Reality). To reshape the entire future
                <br />
                We have also developed An excellence in the field of Game development, Motion graphics, Immersive Media, Visual effects and Virtual production
                <br />
                Join Us into the Immersive Future
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="create-nft">
        <div className="container">
          <div className="row">
            <div data-aos="fade-right" className="col-lg-8">
              <div className="section-heading">
                <h2>Our Work Process.</h2>
                <div className="line-dec mt-2"></div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="main-button">
                <a style={{ backgroundColor: 'black' }} href="/contact">Chat With us</a>
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-4">
              <div className="item first-item">
                <div className="number"></div>
                <div className="icon">
                  <img src={meetingImage} alt="" />
                </div>
                <h4>Discussion</h4>
                <p>Under the client's business, goals, and challenges for building relationships.</p>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-4">
              <div className="item second-item">
                <div className="number"></div>
                <div className="icon">
                  <img src={lightBulbImage} alt="" />
                </div>
                <h4>Ideate</h4>
                <p>Gather ideas and create the first concept for the future product.</p>
              </div>
            </div>
            <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-4">
              <div className="item">
                <div className="icon3">
                  <img src={automationImage} alt="" />
                </div>
                <h4>Execution</h4>
                <p>Provide the first draft of a project, accept minor and major revisions.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    
  );
};

export default Aboutus;
