import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import image1 from '../assets/images/my new/moti.webp';
import image2 from '../assets/images/my new/LevelProtoyping.png';
import image3 from '../assets/images/my new/char.png';
import image4 from '../assets/images/my new/asset.png';
import image5 from '../assets/images/my new/inho.png';
import Footer from './Footer';

const MotionGraphic = () => {
  return (
    <>
      <div className="page-heading normal-space">
        <Container>
          <Row>
            <Col lg={12}>
              <br></br>
              <h6>IDA Creative Studios</h6>
              <h2>Motion Graphics</h2>
              {/* <span>Home > <a href="details.html">Services ></a> <a href="#">Motion Graphics</a></span> */}
            </Col>
          </Row>
        </Container>
      </div>

      <div className="item-details-page">
        <Container>
          <Row>
            <Col lg={12} data-aos="fade-up" data-aos-duration="1000">
              <div className="section-heading">
                <br></br><br></br>
                <h2>Moving stories, captivating <em>audiences</em></h2>
                <div className="line-dec mt-2"></div>
              </div>
            </Col>

            <Col lg={7} data-aos="fade-left" data-aos-duration="1000">
              <div className="left-image">
                <img src={image1} alt="" 
                style={{ 
                  borderRadius: '20px', 
                  height: 'auto', 
                  width: '100%', 
                  maxWidth: '700px', 
                  objectFit: 'cover' 
                }}
                 />
              </div>
            </Col>

            <Col lg={5} className="align-self-center" data-aos="fade-right" data-aos-duration="2000">
              <h3>Motion That Moves Minds</h3>
              <p className="mt-3">
                In the realm of visual communication, motion graphics emerge as the vanguard of creativity, seamlessly weaving together a tapestry of animated elements, text, and imagery to craft immersive narratives that resonate with audiences on a profound level. With each frame meticulously crafted, motion graphics transcend mere aesthetics, becoming a vehicle for storytelling that transcends linguistic and cultural barriers. From the pulsating rhythm of kinetic typography to the mesmerizing dance of animated infographics, every element is carefully orchestrated to engage the senses and evoke emotional resonance. As a dynamic conduit for information and expression, motion graphics possess the transformative power to captivate, educate, and inspire, leaving an indelible imprint on the minds of viewers long after the screen fades to black.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="author-page1">
        <Container>
          <Row>
            <Col lg={12} data-aos="fade-up" data-aos-duration="1000">
              <div className="section-heading" style={{marginTop:'5px'}}>
                <h2 className="text-center">What We <em>offer</em></h2><br />
                <div className="line-dec"></div>
                <h5>
                  Transform content with IDA's dynamic motion graphics. Our team crafts visually stunning animations that elevate your brand and captivate audiences with fluid motion and engaging visual storytelling, ensuring your message stands out in a crowded digital landscape.
                </h5>
              </div>
            </Col>

            <Col lg={3} md={6} data-aos="fade-right" data-aos-duration="1000">
              <div className="item">
                <Row>
                  <Col lg={12}>
                    <img src={image2} alt="" 
                    style={{ 
                      borderRadius: '20px', 
                      height: '248px', 
                      width: '100%', 
                      maxWidth: '700px',
                      maxHeight: '700px', 
                      objectFit: 'cover' 
                    }}
                    />
                    <h4 className="text-center">Engaging Motion <br /> Graphics </h4>
                  </Col>
                  <Col lg={12}>
                    <div className="line-dec"></div>
                    <h6>
                      Capture attention with moving visuals. Our experts create dynamic motion graphics that tell your story.
                    </h6>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg={3} md={6} data-aos="fade-down" data-aos-duration="1000">
              <div className="item">
                <Row>
                  <Col lg={12}>
                    <img src={image3} alt="" 
                    style={{ 
                      borderRadius: '20px', 
                      height: '248px', 
                      width: '100%', 
                      maxWidth: '700px',
                      maxHeight: '700px', 
                      objectFit: 'cover' 
                    }}
                     />
                    <h4 className="text-center">Custom Motion Design Solutions</h4>
                  </Col>
                  <Col lg={12}>
                    <div className="line-dec"></div>
                    <h6>
                      Get unique visuals tailored to you. From logos to videos, we make custom motion designs that stand out.
                    </h6>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg={3} md={6} data-aos="fade-up" data-aos-duration="1000">
              <div className="item">
                <Row>
                  <Col lg={12}>
                    <img src={image4} alt="" 
                    style={{ 
                      borderRadius: '20px', 
                      height: '248px', 
                      width: '100%', 
                      maxWidth: '700px',
                      maxHeight: '700px', 
                      objectFit: 'cover' 
                    }}
                     />
                    <h4 className="text-center">Impactful and Descriptive Visual Storytelling</h4>
                  </Col>
                  <Col lg={12}>
                    <div className="line-dec"></div>
                    <h6>
                      Tell stories that stick with visuals.Our motion graphics bring ideas to life and leave an impression.
                    </h6>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg={3} md={6} data-aos="fade-left" data-aos-duration="1000">
              <div className="item">
                <Row>
                  <Col lg={12}>
                    <img src={image5} alt="" 
                    style={{ 
                      borderRadius: '20px', 
                      height: '248px', 
                      width: '100%', 
                      maxWidth: '700px',
                      maxHeight: '700px', 
                      objectFit: 'cover' 
                    }}
                    />
                    <h4 className="text-center">Professional Motion Graphics Services</h4>
                  </Col>
                  <Col lg={12}>
                    <div className="line-dec"></div>
                    <h6>
                      Upgrade your projects with our services. We craft polished motion graphics that set you apart.
                    </h6>
                  </Col>
                </Row>
              </div>
            </Col>

            <div className="container1">
              <div style={{ fontWeight: 900 }} className="btn"><Link to = "/contact">Join Now</Link></div>
            </div>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default MotionGraphic;
