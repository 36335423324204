import React from 'react';
import { Link } from 'react-router-dom';
import uiuxImage from '../assets/images/my new/uiux.jpg';
import levelPrototypingImage from '../assets/images/my new/LevelProtoyping.png';
import charImage from '../assets/images/my new/char.png';
import assetImage from '../assets/images/my new/asset.png';
import inhoImage from '../assets/images/my new/inho.png';
import Footer from './Footer';

const UiUx = () => {
  return (
    <div>
      <div className="page-heading normal-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <br></br>
              <h6>IDA Interactive Development</h6>
              <h2>UI/UX</h2>
              {/* <span>Home > <a href="#">Services ></a> <a href="#">AR/VR/MR/XR</a></span> */}
            </div>
          </div>
        </div>
      </div>

      <div className="item-details-page">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
              <div className="section-heading">
                <br></br><br></br>
                <h2>Designing experiences, <em>one click at a time.</em></h2>
                <div className="line-dec mt-2"></div>
              </div>
            </div>
            <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-7">
              <div className="left-image">
                <img src={uiuxImage} alt="UI/UX Design" 
                style={{ 
                  borderRadius: '20px', 
                  height: 'auto', 
                  width: '100%', 
                  maxWidth: '700px', 
                  objectFit: 'cover' 
                }}
                />
              </div>
            </div>
            <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-5 align-self-center">
              <h3>Designing Experiences, Delighting Users.</h3>
              <p className="mt-3">
                UI/UX (User Interface/User Experience) design is the synergy between aesthetics and usability, shaping digital interactions to be both visually engaging and intuitively navigable. UI design focuses on the visual elements users interact with, such as buttons and menus, ensuring they are aesthetically pleasing and easy to understand. Meanwhile, UX design delves deeper into the user journey, analyzing how users interact with the interface and optimizing it to provide a seamless and satisfying experience. By prioritizing user needs and preferences, UI/UX designers create immersive digital environments that not only fulfill functional requirements but also leave a lasting positive impression on users.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="author-page1">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
              <div className="section-heading">
                <h2 className="text-center">What We <em>offer</em></h2>
                <br />
                <div className="line-dec"></div>
                <h5>
                  Craft seamless digital experiences with IDA's UI/UX design services. From intuitive interfaces to immersive user journeys, we prioritize user needs, ensuring every interaction is enjoyable and effortless, and creating memorable experiences that foster customer loyalty and drive business success.
                </h5>
                <p></p>
              </div>
            </div>

            <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    
                    <img src={levelPrototypingImage} alt="Intuitive Interface Design" 
                    style={{ 
                      borderRadius: '20px', 
                      height: '248px', 
                      width: '100%', 
                      maxWidth: '700px',
                      maxHeight: '700px', 
                      objectFit: 'cover' 
                    }} />
                    <h4 className="text-center">Intuitive Interface Design</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>Create easy-to-use interfaces. Our designs make navigation seamless for a better user experience.</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-down" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    
                    <img src={charImage} alt="User-Centered Experience Strategy" 
                    style={{ 
                      borderRadius: '20px', 
                      height: '248px', 
                      width: '100%', 
                      maxWidth: '700px',
                      maxHeight: '700px', 
                      objectFit: 'cover' 
                    }}/>
                    <h4 className="text-center">User-Centered Experience Strategy</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>Focus on users from start to finish. Our strategy ensures your design meets their needs.</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    
                    <img src={assetImage} alt="Responsive Design Solutions" 
                    style={{ 
                      borderRadius: '20px', 
                      height: '248px', 
                      width: '100%', 
                      maxWidth: '700px',
                      maxHeight: '700px', 
                      objectFit: 'cover' 
                    }}/>
                    <h4 className="text-center">Responsive Design Solutions</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>Look good on any device. Our designs adapt smoothly to desktop, tablet, and mobile screens.</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-3 col-md-6">
              <div className="item">
                <div className="row">
                  <div className="col-lg-12">
                    
                    <img src={inhoImage} alt="Iterative Prototyping and Testing" 
                    style={{ 
                      borderRadius: '20px', 
                      height: '248px', 
                      width: '100%', 
                      maxWidth: '700px',
                      maxHeight: '700px', 
                      objectFit: 'cover' 
                    }} />
                    <h4 className="text-center">Iterative Prototyping and Testing</h4>
                  </div>
                  <div className="col-lg-12">
                    <div className="line-dec"></div>
                    <div className="row">
                      <h6>Improve with every step. We refine designs based on user feedback for the best results.</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container1">
              <div style={{ fontWeight: '900' }} className="btn">
                <Link to="/contact">Join Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default UiUx;
