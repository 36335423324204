import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './projects.css';
import './project3.css';

import game3 from '../assets/images/img/Game3.jpeg';
import vd from '../assets/images/img/project3.MP4';

function Project3() {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <div className="item-details-page">
      <div className="container">
        <div className="row">
          <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
            <div className="section-heading">
              <h2 style={{ marginTop: '100px' }}>
                <br></br> <br></br>
                Startup Fest Expo - <em>OneYes Startup Expo.</em>
              </h2>
              <div className="line-dec mt-2"></div>
            </div>
          </div>
          <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-7">
            <div className="left-image" style={{ position: 'relative' }}>
              {!isPlaying ? (
                <>
                  <img
                    src={game3}
                    alt="Startup Fest Expo"
                    style={{ 
                      borderRadius: '20px', 
                      height: 'auto', 
                      width: '100%', 
                      maxWidth: '700px', 
                      objectFit: 'cover' 
                    }}
                  />
                  <div className="play-button" onClick={() => setIsPlaying(true)}>
                    <i className="fa fa-play" aria-hidden="true"></i>
                    <span className="play-text">Play Now</span>
                  </div>
                </>
              ) : (
                <video
                  src={vd}// Replace this with your actual video path
                  controls
                  autoPlay
                  style={{ borderRadius: '20px', height: '400px', width: '100%' }}
                />
              )}
            </div>
          </div>
          <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-5 align-self-center">
            <h3>Innovate, Collaborate, Succeed</h3>
            <p className="mt-3">
              Welcome to Startup Fest Expo - OneYes Startup Expo, the premier event for startups to showcase their
              innovations and connect with investors, mentors, and collaborators from around the world. Join us in
              celebrating entrepreneurship and fostering innovation!
            </p>
          </div>
        </div>
      </div>

      <div className="author-page">
        <div className="container">
          <div className="row">
            <div data-aos="fade-down" data-aos-duration="1000" className="col-lg-12">
              <div className="section-heading">
                <h2 className="text-center">
                  <br></br>
                  Key <em>Features</em>
                </h2>
                
                <div className="line-dec"></div>
                <ul>
                  <li>Startup Showcase: Present your startup to a global audience and gain exposure.</li>
                  <li>Investor Networking: Connect with potential investors and secure funding for your venture.</li>
                  <li>Mentorship Opportunities: Receive guidance and support from experienced mentors in your industry.</li>
                  <li>Collaboration Platforms: Explore collaboration opportunities with other startups and industry partners.</li>
                  <li>Panel Discussions: Participate in insightful panel discussions and gain valuable insights into industry trends.</li>
                  <li>Networking Events: Engage in networking events and meet like-minded entrepreneurs, investors, and professionals.</li>
                </ul>
              </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
              <div style={{ marginBottom: '10px' }} className="section-heading">
                <h2 className="text-center">
                  Get <em>Started</em>
                </h2>
                
                <div className="line-dec"></div>
                <h6>
                  Join us at Startup Fest Expo - OneYes Startup Expo and take your startup to the next level. Network,
                  learn, and grow with the support of the vibrant startup community. Let's build the future together!
                </h6>
              </div>
            </div>

            <div className="container1" style={{ marginTop: '20px' }}> {/* Added marginTop for spacing */}
              <div style={{ fontWeight: '900' }} className="btn">
                <Link to="/contact">Register Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Project3;
