import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import logo from '../assets/images/img/IDA.png';
import '../assets/css/index.css';

function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [shouldScrollToFooter, setShouldScrollToFooter] = useState(false);
  const [navigatedToHome, setNavigatedToHome] = useState(false); // New state to track navigation
  const navigate = useNavigate();
  const location = useLocation(); 
  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  

  const handleDropdownToggle = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setDropdownOpen(!isDropdownOpen); // Toggle dropdown
  };

  const handleContactClick = (e) => {
    e.preventDefault();
    navigate('/', { state: { scrollToFooter: true } });
  };

  useEffect(() => {
    if (location.state?.scrollToFooter) {
      setTimeout(() => {
        const footerElement = document.getElementById('footer');
        if (footerElement) {
          footerElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); // Delay to ensure page load
    }
  }, [location.state]);

  const handleHomeClick = (e) => {
    e.preventDefault();
    if (window.location.pathname === '/') {
      const headerElement = document.getElementById('header');
      if (headerElement) {
        headerElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      navigate('/');
      setTimeout(() => {
        const headerElement = document.getElementById('header');
        if (headerElement) {
          headerElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
  };

  return (
    <header className="header-area header-sticky">
      <nav className="main-nav">
        <NavLink to="/#header" className="logo"  onClick={handleHomeClick}>
          <img src={logo} alt="IDA Logo" />
        </NavLink>
        <a className={`menu-trigger ${isMenuOpen ? 'active' : ''}`} onClick={handleMenuToggle}>
          <span></span>
        </a>
        <ul className={`nav ${isMenuOpen ? 'open' : ''}`}>
          <li>
            <HashLink to="/#header" smooth onClick={handleHomeClick}>
              Home
            </HashLink>
          </li>
          <li>
            <NavLink to="/about">
              About Us
            </NavLink>
          </li>
          <li  className={`has-sub ${isDropdownOpen ? 'open' : ''}`} 
               onMouseEnter={() => setDropdownOpen(true)} 
               onMouseLeave={() => setDropdownOpen(false)}
            >
             <NavLink to="/details" className={({ isActive }) => isActive ? 'active' : ''} onClick={handleDropdownToggle}>
                    Services
                    <i className={`fa-solid fa-chevron-down html-chevron ${isDropdownOpen ? 'rotate-icon' : ''}`} style={{ fontSize: '10px', marginLeft: '5px' }}></i> 
              </NavLink>
             <ul className="sub-menu">
              <li><NavLink to="/gamedev">Game Development</NavLink></li>
              <li><NavLink to="/genai">Generative AI</NavLink></li>
              <li><NavLink to="/3d-modelling">3D Modeling/Rendering</NavLink></li>
              <li><NavLink to="/2d-animation">2D/3D Animation</NavLink></li>
              <li><NavLink to="/motion-graphics">Motion Graphics</NavLink></li>
              <li><NavLink to="/vfx">VFX</NavLink></li>
              <li><NavLink to="/ar-vr">AR/VR/MR/XR</NavLink></li>
              <li><NavLink to="/virtual-production">Virtual Production</NavLink></li>
              <li><NavLink to="/cg">Computer Graphics</NavLink></li>
              <li><NavLink to="/ui-ux">UI/UX</NavLink></li>
              <li><NavLink to="/academic-alliances">Academic Alliances</NavLink></li>
              <li><NavLink to="/video-editing">Video Editing</NavLink></li>
            </ul>
          </li>
          <li className='cnt'>
            <HashLink smooth to="/#footer" onClick={handleContactClick}>
              Contact
            </HashLink>
          </li>
          <li>
            <NavLink to="/contact">
              Join Us
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
