import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
//import './vendor/bootstrap/css/bootstrap.min.css'; 
//import './assets/css/fontawesome.css'; 
//import './assets/css/templatemo-liberty-market.css'; 
//import './assets/css/owl.css'; 
//import './assets/css/animate.css';
import Preloader from './components/Preloader'; 


// Import components
import Header from './components/Header';
import MainBanner from './components/MainBanner';
import AboutSection from './components/AboutSection';
import WorkProcess from './components/WorkProcess';
import Services from './components/Services';
import Clients from './components/Clients';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Aboutus from './components/Aboutus';
import Gamedev from './components/Gamedev';
import GenAi from './components/GenAi';
import ThreeDmodel from './components/ThreeDmodel';
import TwoDmodel from './components/TwoDmodel';
import MotionGraphic from './components/MontionGraphic';
import Vfx from './components/Vfx';
import ArVr from './components/ArVr';
import Virtualprod from './components/Virtualprod';
import Comgraphic from './components/Comgraphic';
import UiUx from './components/UiUx';
import Academic from './components/Academic';
import VideoEditing from './components/VideoEditing';
import Project1 from './components/Project1';
import Project2 from './components/Project2';
import Project3 from './components/Project3';
import Project4 from './components/Project4';
import Project5 from './components/Project5';
import Project6 from './components/Project6';
import Project7 from './components/Project7';
import Project8 from './components/Project8';
import Details from './components/Details';

// Scroll to top on route change
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    AOS.init({ duration: 1900 });
    AOS.refresh();
    // Show the preloader whenever the route changes
    const handleRouteChange = () => {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000); // Adjust the delay time to match your needs

      return () => clearTimeout(timer);
    };

    handleRouteChange(); // Initial load
    return handleRouteChange;
  }, [location.pathname]);

  return (
      <>
      {loading ? <Preloader /> : (
        <>
          <Header />
          <Routes>
            <Route path="/" element={
              <>
                <MainBanner /> 
                <AboutSection />
                <WorkProcess />
                <Services />
                <Clients />
                <Footer />
                {/*
                
                
                
                */}
              </>
            } />
            <Route path="/about" element={<Aboutus />} />
            <Route path="/gamedev" element={<Gamedev />} />
            <Route path="/genai" element={<GenAi />} />
            <Route path="/3d-modelling" element={<ThreeDmodel />} />
            <Route path="/2d-animation" element={<TwoDmodel />} />
            <Route path="/motion-graphics" element={<MotionGraphic />} />
            <Route path="/vfx" element={<Vfx />} />
            <Route path="/ar-vr" element={<ArVr />} />
            <Route path="/virtual-production" element={<Virtualprod />} />
            <Route path="/cg" element={<Comgraphic />} />
            <Route path="/ui-ux" element={<UiUx />} />
            <Route path="/academic-alliances" element={<Academic />} />
            <Route path="/video-editing" element={<VideoEditing />} />
            <Route path="/project1" element={<Project1 />} />
            <Route path="/project2" element={<Project2 />} />
            <Route path="/project3" element={<Project3 />} />
            <Route path="/project4" element={<Project4 />} />
            <Route path="/project5" element={<Project5 />} />
            <Route path="/project6" element={<Project6 />} />
            <Route path="/project7" element={<Project7 />} />
            <Route path="/project8" element={<Project8 />} />
            <Route path="/details" element={<Details />} />
            <Route path="/footer" element={<Footer />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </>
      )}
    </>
  );
}

export default function Root() {
  return (
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  ); 
}
