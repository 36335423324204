import React from 'react';
import { Link } from 'react-router-dom';
import './projects.css'

import game2 from '../assets/images/img/Game2.jpg';

function Project2() {
  return (
    <div className="item-details-page">
      <div className="container">
        <div className="row">
          <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
            <div className="section-heading">
              <h2 style={{ marginTop: '100px' }}>
                <br></br><br></br>
                Precision Strike - <em>Mobile Shooting Game.</em>
              </h2>
              <div className="line-dec mt-2"></div>
            </div>
          </div>
          <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-7">
            <div className="left-image">
              <img 
                src={game2}
                alt="Precision Strike" 
                style={{ 
                  borderRadius: '20px', 
                  height: 'auto', 
                  width: '100%', 
                  maxWidth: '700px', 
                  objectFit: 'cover' 
                }} 
              />
            </div>
          </div>
          <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-5 align-self-center">
            <h3>Engage, Aim, Dominate</h3>
            <p className="mt-3">
              Welcome to Precision Strike, the ultimate mobile shooting game experience! Gear up, lock and load, and engage in intense battles against players from around the world in heart-pounding scenarios.
            </p>
          </div>
        </div>
      </div>

      <div className="author-page">
        <div className="container">
          <div className="row">
            <div data-aos="fade-down" data-aos-duration="1000" className="col-lg-12">
              <div className="section-heading">
                <h2 className="text-center">
                  <br></br>
                  Key <em>Features</em>
                </h2>
                <br />
                <div className="line-dec"></div>
                <ul>
                  <li>Intense Battles: Engage in thrilling battles against players from around the world.</li>
                  <li>Customizable Loadouts: Customize your loadouts with a variety of weapons and equipment.</li>
                  <li>Multiplayer Mayhem: Team up with friends or go solo in adrenaline-pumping multiplayer matches.</li>
                  <li>Varied Maps: Explore diverse maps and environments, each offering unique tactical challenges.</li>
                  <li>Regular Updates: Stay ahead with regular updates and new content additions.</li>
                  <li>Gameplay Showcase: Showcase intense gameplay moments and impressive kills in Precision Strike.</li>
                </ul>
              </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
              <div style={{ marginBottom: '10px' }} className="section-heading">
                <h2 className="text-center">
                  Get <em>Started</em>
                </h2>
                <br />
                <div className="line-dec"></div>
                <h6>
                  Join the battle, hone your skills, and dominate the battlefield in Precision Strike. Connect with fellow gamers, participate in tournaments, and rise through the ranks to become a legendary marksman.
                </h6>
              </div>
            </div>

            <div className="container1" style={{ marginTop: '20px' }}> {/* Added marginTop for spacing */}
              <div style={{ fontWeight: '900' }} className="btn">
                <Link to="/contact">Join Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Project2;
