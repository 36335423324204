import React from 'react';
import { Link } from 'react-router-dom';
import './projects.css'

import web from '../assets/images/img/web.png';

function Project5() {
  return (
    <div className="item-details-page">
      <div className="container">
        <div className="row">
          <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
            <div className="section-heading">
              <h2 style={{ marginTop: '100px' }}>
                <br></br><br></br>
                Web XR - <em>Explore the Future of Web XR</em>
              </h2>
              <div className="line-dec mt-2"></div>
            </div>
          </div>
          <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-7">
            <div className="left-image">
              <img
                src={web}
                alt="Wordwide Artwork Ground"
                style={{ 
                  borderRadius: '20px', 
                  height: 'auto', 
                  width: '100%', 
                  maxWidth: '700px', 
                  objectFit: 'cover' 
                }}
              />
            </div>
          </div>
          <div data-aos="fade-right" data-aos-duration="1000" className="col-lg-5 align-self-center">
          <h3>Immerse, Interact, Innovate</h3>
            <p className="mt-3">
              Dive into the future with our Web XR experiences. Engage with digital environments in a way that blurs the line between the virtual and the real. Explore interactive spaces, participate in dynamic scenarios, and push the boundaries of what’s possible. Our Web XR platform is designed to transform the way you experience content, bringing innovation to life in every dimension. Start your immersive journey now!
            </p>
          </div>
        </div>
      </div>

      <div className="author-page">
        <div className="container">
          <div className="row">
            <div data-aos="fade-down" data-aos-duration="1000" className="col-lg-12">
              <div className="section-heading">
                <h2 className="text-center">
                  <br></br>
                  Key <em>Features</em>
                </h2>
                
                <div className="line-dec"></div>
                <ul>
                  <li>Immersive Web XR Experiences: Step into fully interactive 3D environments that blur the lines between physical and digital worlds.</li>
                  <li>Real-Time Interaction: Engage with virtual elements dynamically, responding to your movements and actions in real time.</li>
                  <li>Multi-Sensory Exploration: Experience environments that stimulate your senses with rich visuals, sounds, and haptic feedback.</li>
                  <li>Customizable Interfaces: Tailor your XR experiences to your preferences, adjusting settings for a personalized journey.</li>
                  <li>Enhanced Learning: Discover complex concepts and environments through immersive, hands-on virtual scenarios.</li>
                  <li>Collaborative Spaces: Connect with others in shared virtual environments, enabling teamwork and collective exploration.</li>
                  <li>Next-Generation Technology: Harness the power of Web XR to revolutionize how you interact with digital content and spaces.</li>
               </ul>
              </div>
            </div>

            <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-12">
              <div style={{ marginBottom: '10px' }} className="section-heading">
                <h2 className="text-center">
                  Get <em>Started</em>
                </h2>
               
                <div className="line-dec"></div>
                <h6>Step into the Future with Web XR - Unlock New Dimensions of Interaction! Experience immersive digital environments where the physical and virtual worlds collide. Explore innovative XR experiences that allow you to engage with content like never before. Whether it's educational simulations, virtual showrooms, or interactive storytelling, Web XR brings your ideas to life with unparalleled realism and interactivity. Start your journey into the next frontier of digital engagement today!</h6>
              </div>
            </div>

            <div className="container1" style={{ marginTop: '20px' }}> {/* Added marginTop for spacing */}
              <div style={{ fontWeight: '900' }} className="btn">
                <Link to="/contact">Join Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Project5;
